import styled from 'styled-components';
import { H4, RText } from '../../components/texts';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import { NumberedListBase } from '../../components/list';

const NumberedList = styled(NumberedListBase)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 2.4rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-wrap: wrap;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
	}

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		column-gap: 4.8rem;
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		column-gap: 1.8rem;
		row-gap: 2.4rem;
	}

	& > li {
		display: flex;
		flex-direction: column;
		width: 23%;

		@media only screen and (max-width: ${props => props.theme.tablet_width}) {
			width: 45%;
		}

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			width: 100%;
		}

		& > *:first-child {
			margin-bottom: 1.6rem;

			@media only screen and (max-width: ${props => props.theme.tablet_width}) {
				margin-bottom: 0.8rem;
			}
		}
	}
`;

const Research = () => (
	<Section>
		<SectionCaption>MARKET RESEARCH</SectionCaption>
		<SectionTitle>Competitor analysis and early insights</SectionTitle>
		<SectionDescription>
			The Discovery phase deeply immersed me in the cryptocurrency world, enhancing my understanding of key terminology, aligning with client goals, and
			initiating research into user preferences.
		</SectionDescription>
		<SectionDescription>
			In addition to analyzing qualitative and quantitative data and relevant literature, I explored various online sources for cryptocurrency tracking. I
			also evaluated competitor apps to discern effective strategies and areas for enhancement.
		</SectionDescription>
		<NumberedList>
			<li>
				<H4>Searching information</H4>
				<RText>Users sought cryptocurrency data across various websites, so they had to rely on search functions.</RText>
			</li>
			<li>
				<H4>Horizontal scroll</H4>
				<RText>Most websites used a horizontal scroll, which made it hard to display all information about one currency at once.</RText>
			</li>
			<li>
				<H4>Choosing notifications</H4>
				<RText>The available crypto sources did not give users any option of selecting the notifications they wanted to receive.</RText>
			</li>
			<li>
				<H4>Data analysis</H4>
				<RText>We wanted to add the feature of analyzing the currency data so that there was no need to dive deep into the topic.</RText>
			</li>
		</NumberedList>
	</Section>
);

export default Research;
