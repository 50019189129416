import { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Route, Switch, useLocation } from 'wouter';
import { GlobalStyles, theme } from './styles';
import Header from './components/header';
import Footer from './components/footer';
import Work from './pages/work';
import About from './pages/about';
import Interac from './pages/interac';
import Pledgx from './pages/pledgx';
import CryptoInsights from './pages/crypto';
import PageNotFound from './pages/pageNotFound';
import Fun from './pages/fun';
import EZTax from './pages/eztax';

const Page = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	overflow-x: hidden;
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

const App = () => {
	const [location] = useLocation();

	useEffect(() => window.scrollTo(0, 0), [location]);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />

			<Page>
				<MainContent>
					<Header />

					<Switch>
						<Route path="/" component={Work} />
						<Route path="/fun" component={Fun} />
						<Route path="/about" component={About} />

						<Route path="/interac" component={Interac} />
						<Route path="/pledgx" component={Pledgx} />
						<Route path="/crypto_insights" component={CryptoInsights} />
						<Route path="/eztax" component={EZTax} />
						<Route component={PageNotFound} />
					</Switch>
				</MainContent>

				<Footer />
			</Page>
		</ThemeProvider>
	);
};

export default App;
