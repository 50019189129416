import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import ColorsImage from '../../assets/interac/colours.png';
import TypographyImage from '../../assets/interac/typography.png';
import styled from 'styled-components';
import { H334 } from '../../components/texts';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
		margin-top: 2.4rem;
	}
`;

const ImageTitle = styled(H334)`
	margin-bottom: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 2.4rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 1.6rem;
	}
`;

const ImageContainer = styled.div`
	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		&:not(:last-child) {
			margin-right: 5%;
		}
	}

	&:not(:last-child) {
		margin-bottom: 3.2rem;
	}
`;

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const Visual = () => (
	<Section>
		<SectionCaption>VISUAL DESIGN</SectionCaption>
		<SectionTitle>Guiding design consistency</SectionTitle>
		<SectionDescription>
			Working with another designer, we created a design system to make it easy for both designers and developers to use its components effectively.
		</SectionDescription>
		<SectionDescription>
			We chose blue as our primary colour for its familiarity and compatibility with other colours. Additionally, it was already used in the previous platform, ensuring a seamless
			transition.
		</SectionDescription>
		<Container>
			<ImageContainer>
				<ImageTitle>Colours</ImageTitle>
				<Image src={ColorsImage} />
			</ImageContainer>
			<ImageContainer>
				<ImageTitle>Typography</ImageTitle>
				<Image src={TypographyImage} />
			</ImageContainer>
		</Container>
	</Section>
);

export default Visual;
