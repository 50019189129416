import styled from 'styled-components';
import { H344, RText } from '../components/texts';
import { Section, SectionDescription, SectionTitle } from '.';
import { FunctionComponent } from 'react';

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 6.4rem;

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) and (max-width: ${props => props.theme.tablet_width}) {
		max-width: 840px;
		width: 100%;
		justify-content: space-between;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
		margin-top: 4.8rem;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		margin-right: 200px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		&:not(:last-child) {
			margin-bottom: 3.2rem;
		}
	}
`;

const InfoItems = styled.div`
	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;

		& > * {
			margin-top: 0 !important;

			&:not(:last-child) {
				&::after {
					content: ',';
					margin-right: 0.5rem;
				}
			}
		}
	}
`;

const ColumnTitle = styled(H344)`
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 0.8rem;
	}
`;

type Props = {
	title: string;
	description: string;
	info: {
		title: string;
		items: string[];
	}[];
};

const Info: FunctionComponent<Props> = ({ title, description, info }) => (
	<Section>
		<SectionTitle>{title}</SectionTitle>
		<SectionDescription>{description}</SectionDescription>
		<InfoRow>
			{info.map(({ title, items }, i) => (
				<InfoColumn key={i}>
					<ColumnTitle>{title}</ColumnTitle>
					<InfoItems>
						{items.map((item, i) => (
							<RText key={i}>{item}</RText>
						))}
					</InfoItems>
				</InfoColumn>
			))}
		</InfoRow>
	</Section>
);

export default Info;
