import styled from 'styled-components';
import { Section, SectionCaption, SectionTitle } from '../../sections';
import createResponsiveElement from '../../components/responsive';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right.svg';
import { Fragment, FunctionComponent } from 'react';

const TABLET_WIDTH_OVERRIDE = 1350; // px

const LinearGraphContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
		align-items: center;
		margin-top: 3.2rem;
	}
`;

const NodeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 14rem;
	grid-area: node;
`;

const NodeTitle = styled.p`
	font-size: 1.8rem;
	font-family: 'Kumbh Sans', sans-serif;
	font-weight: 700;
	text-align: center;
	width: 100%;
	padding: 1rem;
	margin-bottom: 2.4rem;
	background: ${props => props.theme.primary_black};
	color: ${props => props.theme.primary_white};

	@media only screen and (max-width: ${TABLET_WIDTH_OVERRIDE}px) {
		margin-bottom: 0.8rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		font-size: 1.4rem;
		font-weight: 400;
	}
`;

const UnorderedList = styled.ul`
	list-style: square;
	list-style-position: inside;
	width: 100%;

	& > li {
		width: max-content;
		white-space: nowrap;
		font-size: 1.6rem;
		font-family: 'Kumbh Sans', sans-serif;
		font-weight: 300;
		margin-top: 0.2rem;
	}
`;

const ArrowRight = styled(ArrowRightIcon)`
	margin: 0 5px;
	flex: 1 1 0;
	margin-top: 2.3rem;
	transform: translateY(-50%);
	grid-area: right_arrow;
`;

const ArrowDown = styled(ArrowDownIcon)`
	margin: 1.2rem 0;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin: 0.8rem 0;
	}
`;

type NodeData = {
	title: string;
	points: string[];
};

type NodeProps = NodeData & {
	arrow: boolean;
};

const Arrow = createResponsiveElement([ArrowRight, Fragment, ArrowDown]);

const Node: FunctionComponent<NodeProps> = ({ title, points, arrow }) => (
	<>
		<NodeContainer>
			<NodeTitle>{title}</NodeTitle>
			<UnorderedList>
				{points.map((point, i) => (
					<li key={i}>{point}</li>
				))}
			</UnorderedList>
		</NodeContainer>
		{arrow && <Arrow />}
	</>
);

type GraphProps = {
	nodes: NodeData[];
};

const LinearGraph: FunctionComponent<GraphProps> = ({ nodes }) => (
	<LinearGraphContainer>
		{nodes.map((node, i) => (
			<Node key={i} {...node} arrow={i + 1 < nodes.length} />
		))}
	</LinearGraphContainer>
);

const SnakeGraphContainer = styled.div`
	max-width: 840px;

	& path {
		fill: ${props => props.theme.primary_black};
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const SnakeGraph: FunctionComponent<GraphProps> = ({ nodes }) => (
	<SnakeGraphContainer>
		<Row>
			<Node {...nodes[0]} arrow={false} />
			<ArrowRight style={{ opacity: 0 }} />
			<Node {...nodes[3]} arrow={false} />
			<ArrowRight />
			<Node {...nodes[4]} arrow={false} />
		</Row>
		<Row>
			<ArrowDown style={{ marginLeft: '7rem', transform: 'translateX(-50%)' }} />
			<ArrowDown style={{ transform: 'scaleY(-1)' }} />
			<ArrowDown style={{ marginRight: '7rem', transform: 'translateX(50%)' }} />
		</Row>
		<Row>
			<Node {...nodes[1]} arrow={false} />
			<ArrowRight />
			<Node {...nodes[2]} arrow={false} />
			<ArrowRight style={{ opacity: 0 }} />
			<Node {...nodes[5]} arrow={false} />
		</Row>
	</SnakeGraphContainer>
);

const Graph = createResponsiveElement([LinearGraph, SnakeGraph, LinearGraph], [TABLET_WIDTH_OVERRIDE, 0]);

const Design = () => (
	<Section>
		<SectionCaption>DESIGN STRATEGY</SectionCaption>
		<SectionTitle>Iterative design process</SectionTitle>
		<Graph
			nodes={[
				{ title: 'DISCOVER', points: ['Existing solutions', 'Current users', 'Product purpose'] },
				{ title: 'RESEARCH', points: ['Competitors', 'User needs', 'Improvements'] },
				{ title: 'DEFINE', points: ['Things lacking', 'Potential features', 'Planning'] },
				{ title: 'DESIGN', points: ['Low-fi layout', 'Data structure', 'Visual design'] },
				{ title: 'PROTOTYPE', points: ['High-fi frames', 'Interaction', 'Initial testing'] },
				{ title: 'TEST', points: ['User feedback', 'Enhancements', 'Reflection'] }
			]}
		></Graph>
	</Section>
);

export default Design;
