import styled from 'styled-components';
import { Text, H3, UnderlinedText, H233, H334, RText, ButtonSmall, RCaption } from '../components/texts';
import { FunctionComponent } from 'react';
import WorkGif from '../assets/work/cover.gif';
import InteracImage from '../assets/work/interac.png';
import PledgxImage from '../assets/work/pledgx.png';
import CryptoInsightsImage from '../assets/work/cryptoinsights.png';
import EZTaxImage from '../assets/work/eztax.png';
import { ReactComponent as RightArrowIcon } from '../assets/icons/short_arrow_right.svg';
import { ReactComponent as LockIcon } from '../assets/icons/lock.svg';
import useTitle from '../hooks/useTitle';
import WouterLink from '../components/link';
import createResponsiveElement from '../components/responsive';
import PageContainer from '../components/page';

const HeroContainer = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 21.2rem;

	@media only screen and (min-width: 1450px) {
		align-items: center;
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 11.4rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
	}
`;

const ImageContainer = styled.div`
	max-width: 400px;

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		max-width: 34%;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		max-height: 40vh;
		width: 80%;
		align-self: center;
		margin-top: 6.4rem;
	}
`;

const HeroImage = styled.img`
	width: 100%;
	height: auto;
`;

const HeroColumn = styled.div`
	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		width: 60%;
	}
`;

const HeroTitle = styled(H3)`
	font-size: 4rem;
	margin-bottom: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		font-size: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 2.4rem;
		font-size: 2.6rem;
	}
`;

const HeroText = styled(Text)`
	margin-top: 2rem;
`;

const HeroTitleAccent = styled.span`
	position: relative;
	background: linear-gradient(
		to top,
		transparent 10%,
		${props => props.theme.primary_yellow} 10%,
		${props => props.theme.primary_yellow} 40%,
		transparent 40%
	);
`;

const ProjectImage = styled.img`
	object-fit: contain;

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		object-position: left;
		width: 45%;
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		object-position: top;
		max-height: 40vh;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 3.2rem;
	}
`;

const ProjectContainer = styled.div`
	display: flex;
	background: ${props => props.theme.primary_grey};
	border: 1px solid ${props => props.theme.secondary_grey};
	border-radius: 8px;
	padding: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 3.2rem;
	}

	&:first-of-type {
		margin-top: 3.2rem;

		@media only screen and (max-width: ${props => props.theme.tablet_width}) {
			margin-top: 1.6rem;
		}
	}

	&:not(:last-child) {
		margin-bottom: 4.8rem;

		@media only screen and (max-width: ${props => props.theme.tablet_width}) {
			margin-bottom: 3.2rem;
		}
	}

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		flex-direction: row;
		justify-content: space-between;
		max-height: 50vh;

		&:nth-of-type(even) {
			flex-direction: row-reverse;

			& ${ProjectImage} {
				object-position: right;
			}
		}
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: column;
		width: 100%;
	}
`;

const ProjectInfo = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		justify-content: space-between;
		width: 50%;
	}
`;

const ProjectText = styled.div`
	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		margin-top: 2.4rem;
		margin-bottom: 3.6rem;
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 2rem;
		margin-bottom: 3.2rem;
	}
`;

const Tags = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-wrap: wrap;
		row-gap: 0.8rem;
		column-gap: 0.6rem;
	}
`;

const Tag = styled(RCaption)`
	background: ${props => props.theme.primary_white};
	color: ${props => props.theme.tertiary_grey};
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 2px;
	padding: 0.8rem 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 0.4rem 1rem;
	}
`;

const Description = styled(RText)`
	margin-top: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 1.2rem;
	}
`;

const Link = styled(createResponsiveElement([Text, Text, ButtonSmall]))<{ $animateIcon: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;

	& ${UnderlinedText} {
		margin-right: 0.5rem;
	}

	&:hover ${UnderlinedText}::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}

	& svg {
		transition: transform 0.2s ease-out;
	}

	&:hover svg {
		transform: translateX(${props => (props.$animateIcon ? '25%' : 0)});
	}
`;

const HeroSection = () => (
	<HeroContainer>
		<HeroColumn>
			<HeroTitle>
				Hi, I’m Ana, a <HeroTitleAccent>Product Designer</HeroTitleAccent> simplifying complex experiences
			</HeroTitle>
			<HeroText>As an immigrant with a background across various industries, I value honesty and diversity.</HeroText>
			<HeroText>Currently @ Interac ✨</HeroText>
		</HeroColumn>

		<ImageContainer>
			<HeroImage src={WorkGif} />
		</ImageContainer>
	</HeroContainer>
);

type ProjectProps = {
	image: string;
	title: string;
	description: string;
	tags: string[];
	url: string;
	locked?: boolean;
};

const Project: FunctionComponent<ProjectProps> = ({ image, title, description, tags, url, locked = false }) => (
	<ProjectContainer>
		<ProjectImage src={image} alt="Project image" />
		<ProjectInfo>
			<div>
				<Tags>
					{tags.map(tag => (
						<Tag key={tag}>{tag}</Tag>
					))}
				</Tags>
				<ProjectText>
					<H334>{title}</H334>
					<Description>{description}</Description>
				</ProjectText>
			</div>
			<WouterLink href={url}>
				<Link $animateIcon={!locked}>
					<UnderlinedText>READ MORE</UnderlinedText>
					{locked ? <LockIcon /> : <RightArrowIcon />}
				</Link>
			</WouterLink>
		</ProjectInfo>
	</ProjectContainer>
);

const Work = () => {
	useTitle('Work');

	return (
		<PageContainer>
			<HeroSection />
			<section>
				<H233>FEATURED WORK</H233>
				<Project
					image={InteracImage}
					title="Interac’s Financial Emulator Redesign"
					description="Redesigned the Financial Emulator used to demo Interac’s new proposed features to financial institutions to foster customer relations and secure deals."
					tags={['REDESIGN', 'WEB', 'MOBILE']}
					url="/interac"
					locked={true}
				/>
				<Project
					image={PledgxImage}
					title="PledgX WIP Dashboard Design"
					description="Designed PledgX WIP (Work in Progress) Module showing surety bond underwriters how contractors’ projects are performing based on their estimates."
					tags={['UX/UI DESIGN', 'WEB']}
					url="/pledgx"
				/>
				<Project
					image={CryptoInsightsImage}
					title="CryptoInsights App Design for Traders"
					description="Founded visual and user experience design for a mobile app that allows beginner and experienced dealers to earn money by cryptocurrency trading."
					tags={['UX/UI DESIGN', 'MOBILE']}
					url="/crypto_insights"
				/>
				<Project
					image={EZTaxImage}
					title="EZTax App for First-Time Tax Filers"
					description="Created the design of a mobile platform that simplifies tax-related information and helps Canadian first-time tax filers submit their tax returns effortless."
					tags={['CONCEPT DEVELOPMENT', 'UX/UI DESIGN', 'MOBILE']}
					url="/eztax"
				/>
			</section>
		</PageContainer>
	);
};

export default Work;
