import { styled } from 'styled-components';
import { H455 } from './texts';

export const Questions = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 4.8rem;
	column-gap: 1.6rem;
	row-gap: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		max-width: 800px;
		margin-top: 3.2rem;
		column-gap: 1.2rem;
		row-gap: 1.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		row-gap: 0.8rem;
	}
`;

export const Question = styled(H455)`
	border: 1px solid ${props => props.theme.primary_black};
	padding: 0.6rem 1.2rem;
	width: max-content;
`;
