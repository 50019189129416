import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import DesignImage1 from '../../assets/interac/low_fi-1.png';
import DesignImage2 from '../../assets/interac/low_fi-2.png';
import DesignImage3 from '../../assets/interac/low_fi-3.png';
import ThreeImages from '../../components/threeImages';

const Design = () => (
	<Section>
		<SectionCaption>ITERATIVE DESIGN</SectionCaption>
		<SectionTitle>Generating wireframe concepts</SectionTitle>
		<SectionDescription>
			After defining the structure, our team went through the series of iterations to find a workable solution that would be the best way to display the
			information required on the dashboard.
		</SectionDescription>
		<ThreeImages images={[DesignImage1, DesignImage2, DesignImage3]} border={true} />
	</Section>
);

export default Design;
