import { Section, SectionTitle, SectionCaption, SectionDescription } from '../../sections';
import DefaultDashboard from '../../assets/interac/dashboards_mobile/Default.png';
import TDDashboard from '../../assets/interac/dashboards_mobile/TD.png';
import BMODashboard from '../../assets/interac/dashboards_mobile/BMO.png';
import ScotiaDashboard from '../../assets/interac/dashboards_mobile/Scotia.png';
import RBCDashboard from '../../assets/interac/dashboards_mobile/RBC.png';
import CIBCDashboard from '../../assets/interac/dashboards_mobile/CIBC.png';
import styled from 'styled-components';
import { ImageCaption } from '../../components/texts';

const Images = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
`;

const ImageContainer = styled.div`
	width: 15%;
	margin-top: 4.8rem;
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		width: 30%;
		margin-top: 3.2rem;
		margin-bottom: 1.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		width: 45%;
		margin-top: 2.4rem;
		margin-bottom: 0.8rem;
	}
`;

const Image = styled.img`
	width: 100%;
	height: auto;
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 8px;
`;

const Customization = () => (
	<Section>
		<SectionCaption>CUSTOMIZATION</SectionCaption>
		<SectionTitle>Creating bank-specific mobile experiences</SectionTitle>
		<SectionDescription>
			In a manner similar to the approach taken with the web screens, I created the mobile version for each bank, ensuring alignment with its unique visual style and components.
		</SectionDescription>
		<Images>
			<ImageContainer>
				<Image src={DefaultDashboard} />
			</ImageContainer>
			<ImageContainer>
				<Image src={TDDashboard} />
			</ImageContainer>
			<ImageContainer>
				<Image src={BMODashboard} />
			</ImageContainer>
			<ImageContainer>
				<Image src={ScotiaDashboard} />
			</ImageContainer>
			<ImageContainer>
				<Image src={RBCDashboard} />
			</ImageContainer>
			<ImageContainer>
				<Image src={CIBCDashboard} />
			</ImageContainer>
		</Images>
		<ImageCaption>Examples of dashboards tailored for different Canadian banks.</ImageCaption>
	</Section>
);

export default Customization;
