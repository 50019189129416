import { FunctionComponent, useEffect, useRef } from 'react';
import DesktopImage from '../assets/images/desktop.png';
import DesktopWebp from '../assets/images/desktop.webp';
import LaptopImage from '../assets/images/laptop.png';
import LaptopWebp from '../assets/images/laptop.webp';
import HorizontalPhoneImage from '../assets/images/phone_horizontal.png';
import HorizontalPhoneWebp from '../assets/images/phone_horizontal.webp';
import VerticalPhoneImage from '../assets/images/phone_vertical.png';
import VerticalPhoneWebp from '../assets/images/phone_vertical.webp';
import HorizontalPhone2Image from '../assets/images/phone2_horizontal.png';
import HorizontalPhone2Webp from '../assets/images/phone2_horizontal.webp';
import VerticalPhone2Image from '../assets/images/phone2_vertical.png';
import VerticalPhone2Webp from '../assets/images/phone2_vertical.webp';
import HorizontalPhone3Image from '../assets/images/phone3_horizontal.png';
import HorizontalPhone3Webp from '../assets/images/phone3_horizontal.webp';
import VerticalPhone3Image from '../assets/images/phone3_vertical.png';
import VerticalPhone3Webp from '../assets/images/phone3_vertical.webp';
import WebpImage from './webp';
import styled from 'styled-components';
import useVisible from '../hooks/useVisible';
import useResponsive, { Size } from '../hooks/useResponsive';

const Container = styled.div`
	position: relative;
`;

const Image = styled(WebpImage)`
	width: 100%;
	height: auto;
`;

const Video = styled.video`
	position: absolute;
	transform: translate(-50%, -50%);
	z-index: -1;
`;

type Styles = {
	maxWidth: string;
	maxHeight: string;
	top: string;
	left: string;
};

type DeviceInfo = {
	src: string;
	webpSrc: string;
	styles: Styles;
	phoneSrc?: string;
	phoneWebpSrc?: string;
	phoneStyles?: Styles;
};

export enum DeviceType {
	Desktop,
	Laptop,
	Phone,
	Phone2,
	Phone3
}

const DEVICE_PROPS: { [key: number]: DeviceInfo } = {
	[DeviceType.Desktop]: {
		src: DesktopImage,
		webpSrc: DesktopWebp,
		styles: {
			maxWidth: '48.5%',
			maxHeight: '44.5%',
			top: '41.25%',
			left: '51%'
		}
	},
	[DeviceType.Laptop]: {
		src: LaptopImage,
		webpSrc: LaptopWebp,
		styles: {
			maxWidth: '46.5%',
			maxHeight: '51.5%',
			top: '44.5%',
			left: '49.5%'
		}
	},
	[DeviceType.Phone]: {
		src: HorizontalPhoneImage,
		webpSrc: HorizontalPhoneWebp,
		styles: {
			maxWidth: '17.75%',
			maxHeight: '60%',
			top: '50.75%',
			left: '48.5%'
		},
		phoneSrc: VerticalPhoneImage,
		phoneWebpSrc: VerticalPhoneWebp,
		phoneStyles: {
			maxWidth: '34.5%',
			maxHeight: '60%',
			top: '49.25%',
			left: '49.25%'
		}
	},
	[DeviceType.Phone2]: {
		src: HorizontalPhone2Image,
		webpSrc: HorizontalPhone2Webp,
		styles: {
			maxWidth: '21%',
			maxHeight: '71%',
			top: '50%',
			left: '50%'
		},
		phoneSrc: VerticalPhone2Image,
		phoneWebpSrc: VerticalPhone2Webp,
		phoneStyles: {
			maxWidth: '35%',
			maxHeight: '56%',
			top: '52%',
			left: '50%'
		}
	},
	[DeviceType.Phone3]: {
		src: HorizontalPhone3Image,
		webpSrc: HorizontalPhone3Webp,
		styles: {
			maxWidth: '18%',
			maxHeight: '68%',
			top: '48.75%',
			left: '50.25%'
		},
		phoneSrc: VerticalPhone3Image,
		phoneWebpSrc: VerticalPhone3Webp,
		phoneStyles: {
			maxWidth: '36%',
			maxHeight: '68%',
			top: '48.75%',
			left: '49.75%'
		}
	}
};

type Props = {
	src: string;
	type: DeviceType;
};

const DeviceVideo: FunctionComponent<Props> = ({ src: videoSrc, type }) => {
	const isBlocked = useRef(false);
	const shouldPause = useRef(false);
	const canControl = useRef(true);
	const size = useResponsive();
	const videoRef = useRef(null);
	const isVisible = useVisible(videoRef, 25);

	useEffect(() => {
		if (!videoRef.current || isBlocked.current) return;
		const video = videoRef.current as HTMLVideoElement;

		if (isVisible) {
			if (canControl.current) {
				canControl.current = false;
				video
					.play()
					.then(() => {
						if (shouldPause.current) video.pause();
					})
					.catch(() => {
						// auto play is forbidden
						video.controls = true;
						isBlocked.current = true;
					})
					.finally(() => {
						canControl.current = true;
						shouldPause.current = false;
					});
			} else {
				shouldPause.current = false;
			}
		} else {
			if (canControl.current) {
				video.pause();
			} else {
				shouldPause.current = true;
			}
		}
	}, [isVisible]);

	const tryToPlay = () => {
		if (!isBlocked.current || !videoRef.current) return;
		const video = videoRef.current as HTMLVideoElement;

		video.play().then(() => {
			isBlocked.current = false;
			video.controls = false;
		});
	};

	const imageSrc = (size === Size.Phone && DEVICE_PROPS[type].phoneSrc) || DEVICE_PROPS[type].src;
	const webpSrc = (size === Size.Phone && DEVICE_PROPS[type].phoneWebpSrc) || DEVICE_PROPS[type].webpSrc;
	const style = (size === Size.Phone && DEVICE_PROPS[type].phoneStyles) || DEVICE_PROPS[type].styles;
	return (
		<Container onClick={tryToPlay}>
			<Image src={imageSrc} webpSrc={webpSrc} />
			<Video style={style} src={videoSrc} ref={videoRef} playsInline loop muted />
		</Container>
	);
};

export default DeviceVideo;
