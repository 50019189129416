import { RefObject, useEffect, useState } from 'react';

// visibilityPercentage: How much of the elements has to appear on screen for it to be considered visible
const useVisible = (ref: RefObject<HTMLElement>, visibilityPercentage: number = 0): boolean => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const checkVisibility = () => {
			if (!ref.current) {
				setIsVisible(false);
				return;
			}

			const minY = window.scrollY;
			const maxY = minY + window.innerHeight;

			const elementY = minY + ref.current.getBoundingClientRect().top;
			const elementHeight = ref.current.clientHeight;

			const elementMinY = elementY + elementHeight * (visibilityPercentage / 100);
			const elementMaxY = elementY + elementHeight * (1 - visibilityPercentage / 100);

			if ((minY < elementMinY && elementMinY < maxY) || (minY < elementMaxY && elementMaxY < maxY)) {
				setIsVisible(true);
				return;
			}

			setIsVisible(false);
		};

		checkVisibility();

		window.addEventListener('resize', checkVisibility);
		window.addEventListener('scroll', checkVisibility);

		return () => {
			window.removeEventListener('resize', checkVisibility);
			window.removeEventListener('scroll', checkVisibility);
		};
	}, [ref, visibilityPercentage]);

	return isVisible;
};

export default useVisible;
