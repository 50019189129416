import { createGlobalStyle } from 'styled-components';

export const sizes = {
	tablet: 1280,
	phone: 768
};

export const theme = {
	primary_black: '#2b2420',
	primary_white: '#ffffff',
	primary_yellow: '#f4be35',
	primary_grey: '#f7f7f7',
	secondary_grey: '#c7c7c7',
	tertiary_grey: '#8f8c8a',
	error_red: '#c91c1c',

	min_desktop_width: `${sizes.tablet + 1}px`,
	tablet_width: `${sizes.tablet}px`,
	min_tablet_width: `${sizes.phone + 1}px`,
	phone_width: `${sizes.phone}px`
};

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%; // 1rem = 10px
    }

    body {
        background: ${theme.primary_white};
        color: ${theme.primary_black};
        font-family: 'Kumbh Sans', sans-serif;
    }
`;
