import { Section, SectionTitle, SectionCaption } from '.';
import { H4, RText } from '../components/texts';
import NumberedList from '../components/list';
import { FunctionComponent } from 'react';

type Point = {
	title: string;
	description: string[];
};

type Props = {
	points: [Point, Point];
};

const Reflection: FunctionComponent<Props> = ({ points }) => (
	<Section>
		<SectionCaption>REFLECTION</SectionCaption>
		<SectionTitle>Key takeaways</SectionTitle>
		<NumberedList>
			{points.map((point, i) => (
				<li key={i}>
					<H4>{point.title}</H4>
					{point.description.map((paragraph, i) => (
						<RText key={i}>{paragraph}</RText>
					))}
				</li>
			))}
		</NumberedList>
	</Section>
);

export default Reflection;
