import styled from 'styled-components';
import { HMW, HMWSmall, Text } from '../components/texts';
import { Section } from '.';
import createResponsiveElement from '../components/responsive';
import { FunctionComponent, ReactNode } from 'react';

const DarkSection = styled(Section)`
	background: ${props => props.theme.primary_black} !important;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		padding: 56px 100px 64px 100px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 48px 36px 56px 36px;
	}
`;

const QuestionText = styled(createResponsiveElement([HMW, HMWSmall, Text]))`
	max-width: 840px;
	margin: 0 auto;
	text-align: center;
	color: ${props => props.theme.primary_white};
`;

type Props = {
	children: ReactNode;
};

const HMWSection: FunctionComponent<Props> = ({ children }) => (
	<DarkSection>
		<QuestionText>
			<span style={{ textDecoration: 'underline' }}>How might we</span> {children}
		</QuestionText>
	</DarkSection>
);

export default HMWSection;
