import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

const MenuContainer = styled.button<{ $opened: boolean }>`
	border: none;
	outline: none;
	background: none;
	width: 32px;
	height: 23px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.4s ease-in-out;
	cursor: pointer;

	& span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: ${props => props.theme.primary_black};
		border-radius: 999px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.2s ease-in-out;
	}

	& span:nth-child(1) {
		top: 0px;
	}

	& span:nth-child(2),
	& span:nth-child(3) {
		top: 9px;
	}

	& span:nth-child(4) {
		top: 18px;
	}

	${props =>
		props.$opened &&
		css`
			& span:nth-child(1) {
				top: 9px;
				width: 0%;
				left: 50%;
			}

			& span:nth-child(2) {
				transform: rotate(45deg);
			}

			& span:nth-child(3) {
				transform: rotate(-45deg);
			}

			& span:nth-child(4) {
				top: 9px;
				width: 0%;
				left: 50%;
			}
		`}
`;

type Props = {
	isOpened: boolean;
	setIsOpened: (value: boolean) => void;
};

const MenuButton: FunctionComponent<Props> = ({ isOpened, setIsOpened }) => {
	return (
		<MenuContainer $opened={isOpened} onClick={() => setIsOpened(!isOpened)}>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</MenuContainer>
	);
};

export default MenuButton;
