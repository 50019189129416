import styled from 'styled-components';
import useResponsive from '../hooks/useResponsive';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '.';
import { FunctionComponent, ReactNode } from 'react';

const Image = styled.img<{ $margin: boolean }>`
	max-width: 1200px;
	width: 100%;
	height: auto;
	${props => !props.$margin && 'margin-top: 0 !important;'}
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

type Props = {
	caption?: string;
	title: string;
	description: ReactNode[];
	images: string[];
	margin?: boolean;
};

const ImageSection: FunctionComponent<Props> = ({ caption, title, description, images, margin = true }) => {
	const size = useResponsive();

	return (
		<Section>
			{caption && <SectionCaption>{caption}</SectionCaption>}
			<SectionTitle>{title}</SectionTitle>
			{description.map((paragraph, i) => (
				<SectionDescription key={i}>{paragraph}</SectionDescription>
			))}
			<Image src={size >= images.length ? images[images.length - 1] : images[size]} $margin={margin} />
		</Section>
	);
};

export default ImageSection;
