import styled from 'styled-components';
import { Text } from './texts';

export const NumberedListBase = styled.ol`
	list-style: none;
	counter-reset: cul;

	& > li {
		counter-increment: cul;

		&::before {
			content: '0' counter(cul);
			font-family: 'Kumbh Sans', sans-serif;
			font-size: 3.2rem;
			color: ${props => props.theme.secondary_grey};
			margin-bottom: 1.6rem;

			@media only screen and (max-width: ${props => props.theme.tablet_width}) {
				margin-bottom: 0.8rem;
				font-size: 2.4rem;
			}
		}
	}
`;

const NumberedList = styled(NumberedListBase)`
	display: flex;
	flex-direction: row;
	column-gap: 4.8rem;
	row-gap: 2.4rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: column;
	}

	& > li {
		display: flex;
		flex-direction: column;
		width: 100%;

		& ${Text} {
			max-width: 800px;
		}

		& > *:first-child {
			margin-bottom: 1.6rem;

			@media only screen and (max-width: ${props => props.theme.tablet_width}) {
				margin-bottom: 0.8rem;
			}
		}
	}
`;

export default NumberedList;
