import styled from 'styled-components';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import { H4, RText } from '../../components/texts';
import WireframeImage from '../../assets/interac/low_fi-3.png';
import NumberedList from '../../components/list';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const Image = styled.img`
	max-width: 1280px;
	width: 100%;
	height: auto;
	margin-top: 3.2rem;
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 8px;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const Wireframe = () => (
	<Section>
		<SectionCaption>CHOSEN WIREFRAME</SectionCaption>
		<SectionTitle>Selecting the optimal layout</SectionTitle>
		<SectionDescription>
			After discussing the reasoning behind each version with our manager and conducting preliminary testing, we chose the third option.
		</SectionDescription>
		<SectionDescription>
			This screen closely resembles a typical bank interface and effectively showcases all the features we aimed to highlight, which resonates strongly
			with our target users.
		</SectionDescription>
		<Container>
			<NumberedList>
				<li>
					<H4>Direct access to key features</H4>
					<RText>Users preferred to access common features on-screen, bypassing menu navigation.</RText>
				</li>
				<li>
					<H4>Scrollable lists & filters</H4>
					<RText>Navigation through pages was burdensome, prompting usage of scrollable elements.</RText>
				</li>
				<li>
					<H4>Streamlined card search</H4>
					<RText>Displaying cards/accounts on-screen sped up locating them.</RText>
				</li>
			</NumberedList>
			<Image src={WireframeImage} />
		</Container>
	</Section>
);

export default Wireframe;
