import styled from 'styled-components';
import { ImageCaption } from '../../components/texts';
import { Section, SectionTitle, SectionCaption, SectionDescription } from '../../sections';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right.svg';
import LowFiImage1 from '../../assets/pledgx/first-iterations-1.png';
import LowFiImage2 from '../../assets/pledgx/first-iterations-2.png';
import useResponsive, { Size } from '../../hooks/useResponsive';

const Container = styled.div`
	display: flex;
	flex-direction: row;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: column;
	}
`;

const Item = styled.div`
	max-width: 532px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		max-width: unset;
	}
`;

const Arrow = styled(ArrowRightIcon)`
	min-width: 32px;
	margin: auto 8px;
	transform: translateY(calc(-50% - 1.6rem));
`;

export const FiImage = styled.img`
	width: 100%;
	height: auto;
	margin-top: 2.4rem;
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 6px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		max-width: 840px;
	}
`;

const LowFi = () => {
	const size = useResponsive();

	return (
		<Section>
			<SectionCaption>FIRST ITERATIONS</SectionCaption>
			<SectionTitle>Coming up with an intuitive layout</SectionTitle>
			<SectionDescription>Synthesizing challenges from our research served as a lens through which we could consider what aspects should be focused on.</SectionDescription>
			<Container>
				<Item>
					<FiImage src={LowFiImage1} />
					<ImageCaption style={{ marginTop: '0.8rem' }}>Low-fi</ImageCaption>
				</Item>
				{size === Size.Desktop && <Arrow />}
				<Item>
					<FiImage src={LowFiImage2} />
					<ImageCaption style={{ marginTop: '0.8rem' }}>Mid-fi</ImageCaption>
				</Item>
			</Container>
		</Section>
	);
};

export default LowFi;
