import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import ColorsImage from '../../assets/eztax/colors.png';
import TypographyImage from '../../assets/eztax/typography.png';
import styled from 'styled-components';
import { H334 } from '../../components/texts';
import createResponsiveElement from '../../components/responsive';
import { FunctionComponent } from 'react';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1280px;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		row-gap: 3.2rem;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: max(4rem, 8%);
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled(H334)`
	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 0.8rem;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const Image = styled.img`
	width: 100%;
	height: auto;
	margin-top: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 2.4rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 1.6rem;
	}
`;

type ImageProps = {
	title: string;
	description: string[];
	image: string;
};

type ImagesProps = {
	images: [ImageProps, ImageProps];
};

const Images: FunctionComponent<ImagesProps> = ({ images }) => (
	<Container>
		<Row>
			<Column style={{ flex: '2 1 0' }}>
				{images[0].description.map((paragraph, i) => (
					<SectionDescription key={i}>{paragraph}</SectionDescription>
				))}
			</Column>
			<Column style={{ flex: '1 1 0' }}>
				{images[1].description.map((paragraph, i) => (
					<SectionDescription key={i}>{paragraph}</SectionDescription>
				))}
			</Column>
		</Row>
		<Row>
			<Column style={{ flex: '2 1 0' }}>
				<ImageContainer>
					<Title>{images[0].title}</Title>
					<Image src={images[0].image} />
				</ImageContainer>
			</Column>
			<Column style={{ flex: '1 1 0' }}>
				<ImageContainer>
					<Title>{images[1].title}</Title>
					<Image src={images[1].image} />
				</ImageContainer>
			</Column>
		</Row>
	</Container>
);

const ImagesPhone: FunctionComponent<ImagesProps> = ({ images }) => (
	<Container>
		<Column>
			<Title>{images[0].title}</Title>
			{images[0].description.map((paragraph, i) => (
				<SectionDescription key={i}>{paragraph}</SectionDescription>
			))}
			<Image src={images[0].image} />
		</Column>
		<Column>
			<Title>{images[1].title}</Title>
			{images[1].description.map((paragraph, i) => (
				<SectionDescription key={i}>{paragraph}</SectionDescription>
			))}
			<Image src={images[1].image} />
		</Column>
	</Container>
);

const RImages = createResponsiveElement([Images, Images, ImagesPhone]);

const StyleGuide = () => (
	<Section>
		<SectionCaption>STYLE GUIDE</SectionCaption>
		<SectionTitle>Visual design in the financial platform</SectionTitle>
		<RImages
			images={[
				{
					title: 'Colours',
					description: [
						'The colour palette, dominated by purple hues, communicates a sense of professionalism essential to the financial industry.',
						'Such colours also enhance accessibility by providing excellent visual contrast and depth, making it easier to interact with the platform.'
					],
					image: ColorsImage
				},
				{
					title: 'Typography',
					description: [
						'Fonts and typefaces are carefully chosen to ensure information is easy to read and understand, contributing to a more intuitive user experience.'
					],
					image: TypographyImage
				}
			]}
		/>
	</Section>
);

export default StyleGuide;
