import styled from 'styled-components';
import createResponsiveElement from './responsive';

export const H2 = styled.h2`
	font-family: 'Lexend', sans-serif;
	font-size: 3.2rem;
	font-weight: 700;
	color: #000000;
`;

export const H3 = styled.h3`
	font-family: 'Lexend', sans-serif;
	font-size: 2.4rem;
	font-weight: 600;
	color: ${props => props.theme.primary_black};
`;

export const H4 = styled.h4`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 2.2rem;
	font-weight: 700;
	color: ${props => props.theme.primary_black};
`;

export const H5 = styled.h5`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
	color: ${props => props.theme.primary_black};
`;

export const HMW = styled.h2`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 3.2rem;
	font-weight: 400;
	color: ${props => props.theme.primary_black};
`;

export const HMWSmall = styled(HMW)`
	font-size: 2.4rem;
`;

export const Text = styled.p`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 2rem;
	font-weight: 300;
	color: ${props => props.theme.primary_black};

	& + & {
		margin-top: 0.8rem;
	}
`;

export const TextSmall = styled(Text)`
	font-size: 1.6rem;
`;

export const ButtonSmall = styled.button`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 1.6rem;
	font-weight: 500;
	color: ${props => props.theme.primary_black};
	background: none;
	border: none;
`;

export const Caption = styled.p`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;
	text-transform: uppercase;
	color: ${props => props.theme.primary_black};
`;

export const CaptionSmall = styled(Caption)`
	font-size: 1.2rem;
`;

export const ImageCaption = styled.p`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 1.6rem;
	font-weight: 200;
	text-align: center;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		font-size: 1.2rem;
	}
`;

export const UnderlinedText = styled.span<{ $keepUnderlined?: boolean }>`
	position: relative;
	display: inline-block;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: ${props => props.theme.primary_black};
		transform: scaleX(${props => (props.$keepUnderlined ? 1 : 0)});
		transform-origin: bottom right;
		transition: transform 0.2s ease-out;
	}

	/* disable hover on touch screens, otherwise some links gets stuck with :hover being active */
	@media (pointer: fine) {
		&:hover::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
`;

export const H223 = createResponsiveElement([H2, H2, H3]);
export const H233 = createResponsiveElement([H2, H3, H3]);
export const H234 = createResponsiveElement([H2, H3, H4]);
export const H334 = createResponsiveElement([H3, H3, H4]);
export const H344 = createResponsiveElement([H3, H4, H4]);
export const H345 = createResponsiveElement([H3, H4, H5]);
export const H455 = createResponsiveElement([H4, H5, H5]);
export const H445 = createResponsiveElement([H4, H4, H5]);
export const RText = createResponsiveElement([Text, Text, TextSmall]);
export const RCaption = createResponsiveElement([Caption, Caption, CaptionSmall]);
