import styled from 'styled-components';
import { H2, H3, H445, RText, Text, TextSmall } from '../components/texts';
import AboutImage from '../assets/about/photo.jpg';
import useTitle from '../hooks/useTitle';
import createResponsiveElement from '../components/responsive';
import { FunctionComponent } from 'react';
import PageContainer from '../components/page';

const SpeechBubbleContainer = styled.div`
	position: relative;
	z-index: 1;
`;

const SpeechBubble = styled.div`
	--bbArrowSize: 40px;

	background: ${props => props.theme.primary_grey};
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 8px;
	font-size: 2rem;
	position: absolute;
	width: 100%;

	&::before {
		content: '';
		z-index: -1;
		position: absolute;
		background: ${props => props.theme.tertiary_grey};
		height: calc(var(--bbArrowSize) + 5px);
		width: calc(var(--bbArrowSize) / 2 * 3 + 7px);
	}

	&::after {
		content: '';
		position: absolute;
		background: ${props => props.theme.primary_grey};
		height: var(--bbArrowSize);
		width: calc(var(--bbArrowSize) / 2 * 3);
	}

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		margin-bottom: calc(var(--bbArrowSize) / 2);
		padding: 1.8rem 2.4rem;
		left: -100px;
		top: 0;

		&::before {
			left: -2px;
			top: calc(100% - 4px);
			clip-path: polygon(25% 0, 100% 0, 0% 100%);
		}

		&::after {
			left: 0;
			top: calc(100% - 2px);
			clip-path: polygon(25% 0, 100% 0, 0% 100%);
		}
	}

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) and (max-width: ${props => props.theme.tablet_width}) {
		padding: 1.2rem 2rem;
		left: -100px;
		bottom: 20px;
		width: calc(100% + 75px);
		min-width: 480px;

		&::before {
			left: -2px;
			bottom: calc(100% - 4px);
			clip-path: polygon(0 100%, 100% 0, 75% 100%);
			transform: scaleX(-1);
		}

		&::after {
			left: 0;
			bottom: calc(100% - 2px);
			clip-path: polygon(0 100%, 100% 0, 75% 100%);
			transform: scaleX(-1);
		}
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 1.2rem 2rem;
		left: 0px;
		top: -40px;
		width: 100%;
		font-size: 1.8rem;
		font-weight: 500;

		&::before {
			left: 40px;
			bottom: calc(100% - 4px);
			clip-path: polygon(0 100%, 100% 0, 75% 100%);
		}

		&::after {
			left: 45px;
			bottom: calc(100% - 2px);
			clip-path: polygon(0 100%, 100% 0, 75% 100%);
		}
	}
`;

const ImageContainer = styled.div`
	flex: 1 0 290px;
	margin-right: 6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex: 1 0 218px;
		margin-right: 3.6rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex: unset;
		min-width: 250px;
		width: 100%;
		max-width: 75%;
		margin: 0 auto;
	}
`;

const Image = styled.img`
	border-radius: 12px;
	width: 100%;
	height: auto;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 3.2rem;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	flex: 2 1 586px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		& ${Text} {
			&:not(:first-of-type) {
				margin-top: 2.4rem;
			}
		}
	}
`;

const Career = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 11.6rem;
	row-gap: 6.4rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		row-gap: 4.8rem;
	}
`;

const JobContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const JobRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const Separator = styled(H445)`
	color: ${props => props.theme.primary_yellow};
	margin: 0 0.8rem;
	font-weight: 500;
`;

const DateTime = styled.p`
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 1.6rem;
	font-weight: 200;
	margin-top: 0.8rem;
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 0.4rem;
		margin-bottom: 1.2rem;
	}
`;

type InfoProps = {
	introduction: string[];
	bubble: string;
	description: string[];
};

const DesktopInfo: FunctionComponent<InfoProps> = ({ introduction, bubble, description }) => (
	<Row style={{ paddingBottom: '4rem' }}>
		<ImageContainer>
			<Image src={AboutImage} />
		</ImageContainer>
		<Column>
			<div style={{ marginBottom: '2.4rem' }}>
				{introduction.map((line, i) => (
					<H2 key={i}>{line}</H2>
				))}
			</div>
			<SpeechBubbleContainer>
				<SpeechBubble style={{ position: 'relative', opacity: 0 }}>{bubble}</SpeechBubble>
				<SpeechBubble>{bubble}</SpeechBubble>
			</SpeechBubbleContainer>
			{description.map((paragraph, i) => (
				<RText key={i}>{paragraph}</RText>
			))}
		</Column>
	</Row>
);

const TabletInfo: FunctionComponent<InfoProps> = ({ introduction, bubble, description }) => (
	<Column style={{ maxWidth: '840px' }}>
		<Row>
			<ImageContainer>
				<Image src={AboutImage} />
			</ImageContainer>
			<Column style={{ justifyContent: 'space-between' }}>
				<div>
					{introduction.map((line, i) => (
						<H3 key={i}>{line}</H3>
					))}
				</div>
				<SpeechBubbleContainer>
					<SpeechBubble>{bubble}</SpeechBubble>
				</SpeechBubbleContainer>
			</Column>
		</Row>
		{description.map((paragraph, i) => (
			<RText key={i}>{paragraph}</RText>
		))}
	</Column>
);

const PhoneInfo: FunctionComponent<InfoProps> = ({ introduction, bubble, description }) => (
	<Column>
		{introduction.map((line, i) => (
			<H3 key={i}>{line}</H3>
		))}
		<ImageContainer style={{ marginTop: '3.2rem' }}>
			<Image src={AboutImage} />
		</ImageContainer>
		<SpeechBubbleContainer>
			<SpeechBubble style={{ position: 'relative', opacity: 0 }}>{bubble}</SpeechBubble>
			<SpeechBubble>{bubble}</SpeechBubble>
		</SpeechBubbleContainer>
		{description.map((paragraph, i) => (
			<TextSmall key={i}>{paragraph}</TextSmall>
		))}
	</Column>
);

const Info = createResponsiveElement([DesktopInfo, TabletInfo, PhoneInfo]);

type JobProps = {
	position: string;
	company: string;
	datetime: string;
	description: string;
};

const Job: FunctionComponent<JobProps> = ({ position, company, datetime, description }) => (
	<JobContainer>
		<JobRow>
			<H445>{position}</H445>
			<Separator>|</Separator>
			<RText>{company}</RText>
		</JobRow>
		<DateTime>{datetime}</DateTime>
		<RText style={{ maxWidth: '840px' }}>{description}</RText>
	</JobContainer>
);

const About = () => {
	useTitle('About');

	return (
		<PageContainer>
			<Info
				introduction={['Hi there!', 'I’m Anastasiia, but you can call me Ana']}
				bubble="Living in the vibrant city of Toronto, I’m a product designer who values honesty, human connection, growth, and accessibility 💛"
				description={[
					"I am a product designer at Interac and a recent graduate of Sheridan College's Bachelor of Interaction Design. I've had experiences working in the startup world, collaborating with teams across various industries.",
					'Apart from design, I love to read, express myself through painting, explore culinary delights, and tackle the challenge to master French.',
					'Originally from Ukraine, I’ve learnt that time is too precious to waste on uninspiring pursuits. So, here I am, infusing my designs with passion, integrity, and a touch of Euro charm. Whether making lives better or just easier, I’m here for it - and I’d love to bring that energy to your team!!'
				]}
			/>
			<Career>
				<Job
					position="Product Design Intern"
					company="Interac Corp."
					datetime="Jan 2024 - Present. Toronto, ON, Canada"
					description="Creating Canada’s first digital identities!!"
				/>
				<Job
					position="Product Designer"
					company="PledgX"
					datetime="May 2022 - Present. Toronto, ON, Canada"
					description="Led design of PledgX - construction management software helping contractors find new projects and connect with buyers."
				/>
				<Job
					position="UI/UX Design Intern"
					company="Interac Corp."
					datetime="May 2023 - Aug 2023"
					description="Supported product development. Shipped the design of a web and mobile emulator used to demo Interac’s features to financial institutions."
				/>
				<Job
					position="UI/UX Designer"
					company="Soft Point"
					datetime="Apr 2021 - Sep 2021"
					description="Created a 0-1 design of a cryptocurrency trading mobile app and deployed its MVP, leading to 10,000 downloads on Google Play."
				/>
			</Career>
			<Career>
				<Job
					position="VP of Marketing"
					company="IXDA, Sheridan College"
					datetime="Apr 2022 - Apr 2023"
					description="Planned projects to execute events, workshops, and initiatives to strengthen design community within the IXD program. Provided mentorship."
				/>
				<Job
					position="Designer"
					company="Youth City Council"
					datetime="Apr 2021 - Sep 2021"
					description="Designed marketing materials for the local initiatives promoting student success."
				/>
			</Career>
		</PageContainer>
	);
};

export default About;
