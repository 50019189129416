import { Section, SectionTitle, SectionCaption, SectionDescription } from '.';
import { FunctionComponent, ReactNode } from 'react';
import NumberedList from '../components/list';

type Props = {
	caption: string;
	title?: string;
	description?: ReactNode[];
	children: [ReactNode, ReactNode, ReactNode];
};

const ThreeColumns: FunctionComponent<Props> = ({ caption, title, description, children }) => (
	<Section>
		<SectionCaption>{caption}</SectionCaption>
		{title && <SectionTitle>{title}</SectionTitle>}
		{description && description.map((paragraph, i) => <SectionDescription key={i}>{paragraph}</SectionDescription>)}
		<NumberedList style={description && { marginTop: '2.4rem' }}>{children}</NumberedList>
	</Section>
);

export default ThreeColumns;
