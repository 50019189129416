import { FunctionComponent } from 'react';
import { css, styled } from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
		margin-top: 2.4rem;
		row-gap: 1.6rem;
	}
`;

const Image = styled.img<{ $border: boolean }>`
	${props =>
		props.$border &&
		css`
			border: 1px solid ${props => props.theme.tertiary_grey};
			border-radius: 8px;
		`}

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		width: 32%;
	}
`;

type Props = {
	images: string[];
	border?: boolean;
};

const ThreeImages: FunctionComponent<Props> = ({ images, border = false }) => (
	<Container>
		{images.map((image, i) => (
			<Image src={image} key={i} $border={border} />
		))}
	</Container>
);

export default ThreeImages;
