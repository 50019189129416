import { FunctionComponent } from 'react';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '.';

type Props = {
	caption?: string;
	title: string;
	description: string[];
};

const TextSection: FunctionComponent<Props> = ({ caption, title, description }) => (
	<Section>
		{caption && <SectionCaption>{caption}</SectionCaption>}
		<SectionTitle>{title}</SectionTitle>
		{description.map((paragraph, i) => (
			<SectionDescription key={i}>{paragraph || <>&nbsp;</>}</SectionDescription>
		))}
	</Section>
);

export default TextSection;
