import { ImageCaption } from '../../components/texts';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import DashboardImage1 from '../../assets/interac/iteractions-1.png';
import DashboardImage2 from '../../assets/interac/iteractions-2.png';
import DashboardImage3 from '../../assets/interac/iteractions-3.png';
import DashboardImage4 from '../../assets/interac/iteractions-4.png';
import styled from 'styled-components';

const Images = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 4.8rem;
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.2rem;
		margin-bottom: 1.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
		margin-bottom: 0.8rem;
	}
`;

const Image = styled.img`
	max-width: 23%;
	width: 100%;
	height: auto;
`;

const Dashboard = () => (
	<Section>
		<SectionCaption>DASHBOARD ITERATIONS</SectionCaption>
		<SectionTitle>Designing the home screen</SectionTitle>
		<SectionDescription>
			The idea of having a card on the main screen was born by questioning whether we needed to display the account information like this at all.
		</SectionDescription>
		<SectionDescription>The plan was to mimic the experience users had in real life to make them find information they needed easier.</SectionDescription>
		<Images>
			<Image src={DashboardImage1} />
			<Image src={DashboardImage2} />
			<Image src={DashboardImage3} />
			<Image src={DashboardImage4} />
		</Images>
		<ImageCaption>Design iterations exploring ways to reimagine the home screen and alleviate split attention by chunking information.</ImageCaption>
	</Section>
);

export default Dashboard;
