import DeviceVideo, { DeviceType } from '../../components/deviceVideo';
import PledgxCoverImage from '../../assets/pledgx/cover.png';
import PledgxCoverWebp from '../../assets/pledgx/cover.webp';
import PledgxDemo from '../../assets/pledgx/demo.mov';
import useTitle from '../../hooks/useTitle';
import { CoverImage } from '../../sections';
import Info from '../../sections/info';
import TextSection from '../../sections/text';
import HMWSection from '../../sections/hmw';
import Underwriting from './underwriting';
import Design from './design';
import Research from './research';
import Personas from './personas';
import LowFi from './lowfi';
import MidHiFi from './midhifi';
import Reflection from '../../sections/reflection';
import styled from 'styled-components';
import ThreeColumns from '../../sections/threeColumns';
import { RText, H4 } from '../../components/texts';
import MidFiImage1 from '../../assets/pledgx/design-adjustment-1.png';
import MidFiImage2 from '../../assets/pledgx/design-adjustment-2.png';
import HighFiImage1 from '../../assets/pledgx/last-changes-1.png';
import HighFiImage2 from '../../assets/pledgx/last-changes-2.png';

const Underline = styled.span`
	text-decoration: underline;
`;

const Pledgx = () => {
	useTitle('PledgX');

	return (
		<div>
			<CoverImage src={PledgxCoverImage} webpSrc={PledgxCoverWebp} />
			<Info
				title="Dashboard design for financial underwriting workflows"
				description="PledgX Work in Progress Module is a digital report used by surety bond underwriters to assess how contractors’ projects are performing based on their estimates."
				info={[
					{ title: 'My Role', items: ['UX Designer', 'UI Designer'] },
					{ title: 'Timeline', items: ['2 weeks'] },
					{ title: 'Tools', items: ['Figma', 'FigJam'] }
				]}
			/>
			<TextSection
				caption="THE PROBLEM"
				title="Monitoring construction projects is time-consuming"
				description={[
					'Work in Progress reports are included in all types of financial statements in the construction field to assess project progress and determine whether it is over-billed or under-billed on the jobs under way.',
					'This is a time-consuming process that has little room for error.'
				]}
			/>
			<Underwriting />
			<HMWSection>automate creating Work in Progress (WIP) reports to provide updates on project trends and identify problems before they affect the job progress?</HMWSection>
			<DeviceVideo src={PledgxDemo} type={DeviceType.Desktop} />
			<Design />
			<ThreeColumns caption="GOALS">
				<li>
					<H4>
						<Underline>Digitalize</Underline> reports to remove redundant paperwork.
					</H4>
				</li>
				<li>
					<H4>
						<Underline>Optimize</Underline> the process to make it fast and efficient.
					</H4>
				</li>
				<li>
					<H4>
						<Underline>Give</Underline> users an overview of contractor trends.
					</H4>
				</li>
			</ThreeColumns>
			<Research />
			<Personas />
			<ThreeColumns caption="KEY INSIGHTS" title="Common mistakes in WIP accounting">
				<li>
					<H4>Failure to update project estimates</H4>
					<RText>Updating estimates is crucial to ensure that the WIP report provides an accurate reflection of the projects’s financial landscape.</RText>
				</li>
				<li>
					<H4>Inconsistent reporting periods</H4>
					<RText>Using inconsistent reporting periods for generating WIP reports makes it difficult to track progress and trends accurately.</RText>
				</li>
				<li>
					<H4>Incomplete or inaccurate data entry</H4>
					<RText>Failing to consistently and accurately record all project-related costs, billings, and progress leads to incomplete or inaccurate WIP reports.</RText>
				</li>
			</ThreeColumns>
			<LowFi />
			<MidHiFi
				caption="DESIGNS ADJUSTMENT"
				title="Refining the mid-fi prototype to address user needs"
				description={[
					'After conducting user testing, we understood that the layout of the dashboard did not exactly meet all the user needs and thus, should have been changed.',
					'Here are our main discoveries:'
				]}
				steps={[
					{
						title: 'Showcasing the project completion percentage',
						description: 'We discovered that displaying the progress is crucial for assessing if the project is on track to meet its deadline.',
						image: MidFiImage1
					},
					{
						title: 'Emphasizing the project progress',
						description: 'Users pointed out that the project timeline displaying key progress indicators was the first thing to look at.',
						image: MidFiImage2
					}
				]}
			/>
			<MidHiFi
				caption="LAST CHANGES"
				title="Testing the high-fi prototype"
				description={['We tested the first high-fi prototype with 6 users and discovered the following areas for improvement:']}
				steps={[
					{
						title: 'Rethinking the display of Change Orders',
						description: 'Users told us that the reflection of Change Orders was confusing as they could be input randomly, unlike Progress Payments happening monthly.',
						image: HighFiImage1
					},
					{
						title: 'Replacing the time tile with Billings',
						description:
							'We found that billings was an important element for billing the clients, while the time tile was repeating the data on the progress bar, which was redundant.',
						image: HighFiImage2
					}
				]}
			/>
			<Reflection
				points={[
					{
						title: 'Be flexible but do not be afraid to push back',
						description: [
							'Despite initial pushback from the developers, user testing showed that the scrollable timeline feature was indispensable for enhancing user engagement.',
							'Thus, a collective decision was made to retain it, and the overall dashboard was successfully developed over the span of approximately one month.'
						]
					},
					{
						title: 'Be precise and overcommunicate',
						description: [
							'Clear communication can streamline processes and reduce unnecessary revisions. Providing thorough explanations of designs in language developers understand makes their implementation smoother.',
							'Remember, design is our job. By providing direction, we set developers up for efficient project execution.'
						]
					}
				]}
			/>
		</div>
	);
};

export default Pledgx;
