import useTitle from '../../hooks/useTitle';
import EZTaxCover from '../../assets/eztax/cover.png';
import EZTaxCoverWebp from '../../assets/eztax/cover.webp';
import { CoverImage, Section } from '../../sections';
import Info from '../../sections/info';
import TextSection from '../../sections/text';
import HMWSection from '../../sections/hmw';
import DeviceVideo, { DeviceType } from '../../components/deviceVideo';
import EZTaxDemo from '../../assets/eztax/demo.mov';
import ThreeColumns from '../../sections/threeColumns';
import { H4, RText } from '../../components/texts';
import Research from './research';
import Personas from './personas';
import ImageSection from '../../sections/image';
import styled from 'styled-components';
import StyleGuide from './guide';
import FinalIterations from './final';
import FirstIterations from './iterations';
import Reflection from '../../sections/reflection';
import UserFlowImage from '../../assets/eztax/user-flow.png';
import DesignElementsImage from '../../assets/eztax/design-elements.png';
import VirtualAssistantImage from '../../assets/eztax/assistant.png';

const Bold = styled.span`
	font-weight: 600;
`;

const EZTax = () => {
	useTitle('EZTax');

	return (
		<div>
			<CoverImage src={EZTaxCover} webpSrc={EZTaxCoverWebp} />
			<Info
				title="Mobile platform for filing and learning about taxes"
				description="EZTax is a mobile platform that helps first-time tax filers in Canada get insights on taxes and tax returns by providing simplified information and personalized guidance to prevent costly mistakes and reduce financial stress."
				info={[
					{ title: 'My Role', items: ['UX Researcher', 'UX/UI Designer', 'Illustrator'] },
					{ title: 'Timeline', items: ['3 months'] },
					{ title: 'Tools', items: ['Figma', 'FigJam'] }
				]}
			/>
			<TextSection
				caption="THE PROBLEM"
				title="Filing taxes is challenging and time-consuming"
				description={[
					'First-time tax filers in Canada struggle with complex tax systems, leading to confusion, errors, and financial stress. Existing resources lack personalized guidance and simplified information, hindering a smooth tax experience.'
				]}
			/>
			<TextSection
				title="Most users do not file their taxes themselves"
				description={[
					'According to the Canada Revenue Agency (CRA), 53% of tax returns are filed with the system used by accountants to electronically file taxes on behalf of clients.',
					'Due to perceived complexity, 75% of individuals get someone else to do their tax returns before even trying to understand the tax system independently.'
				]}
			/>
			<HMWSection>make information about taxes and tax returns more accessible to first-time tax-filers in Canada?</HMWSection>
			<DeviceVideo src={EZTaxDemo} type={DeviceType.Phone3} />
			<Section style={{ display: 'none' }} /> {/* Skip white section */}
			<TextSection
				caption="INTENDED USERS"
				title="First-time tax filers seek early accountant help"
				description={[
					'After conducting secondary research and consulting with two accountants regarding their clients, I discovered that young individuals, especially those filing taxes for the first time, encounter the greatest difficulty in understanding the tax system.',
					'Consequently, they are more inclined to seek assistance from an accountant earlier and more frequently than other demographic groups.',
					'As a result, I chose to focus on Canadian residents filing their taxes for the first time.'
				]}
			/>
			<ThreeColumns caption="GOALS">
				<li>
					<H4>Enable Canadian first-time filers to navigate the tax process confidently.</H4>
				</li>
				<li>
					<H4>Foster tax and financial literacy among the Canadian population.</H4>
				</li>
				<li>
					<H4>Convey the platform’s user-friendly approach and educational benefits.</H4>
				</li>
			</ThreeColumns>
			<Research />
			<Personas />
			<ThreeColumns caption="KEY INSIGHTS" title="Main reasons for avoiding direct engagement with taxes">
				<li>
					<H4>Negative feelings associated with taxes</H4>
					<RText>Many people are stressed by the though about taxes and tax returns, and some feel scared about making a mistake.</RText>
				</li>
				<li>
					<H4>Information overload on the Internet</H4>
					<RText>Users expressed interest in learning about the tax system but were confused by the amount of information.</RText>
				</li>
				<li>
					<H4>Accessibility limitations in existing sources </H4>
					<RText>Most of the sources use specific finance-related terminology that is not intuitive for people outside of the industry.</RText>
				</li>
			</ThreeColumns>
			<ImageSection
				caption="USER FLOW"
				title="Dividing the platform into two sections"
				description={[
					'Brainstorming the functionality of my tax platform resulted in creating two sections.',
					<>
						The first section was dedicated to <Bold>educating users about taxes</Bold>, where they could engage with readings and answer the
						following related questions.
					</>,
					<>
						The second section of the platform was meant to <Bold>help users file their tax returns</Bold> by explaining the process and dividing it
						into steps to ensure clarity and prevent confusion.
					</>
				]}
				images={[UserFlowImage]}
			/>
			<FirstIterations />
			<ThreeColumns
				caption="PRELIMINARY TESTING"
				title="Considering early feedback to simplify UX"
				description={[
					'In the initial user testing, my objectives were to assess the clarity of the overall concept and evaluate the effectiveness of the content and navigation.',
					'I guided the participants through the initial prototype and gathered the following feedback for improvement:'
				]}
			>
				<li>
					<H4>Explain complex terms</H4>
					<RText>
						It was hard for most of participants to understand some of the terms and concepts that were specific to the financial industry.
					</RText>
				</li>
				<li>
					<H4>Manage expectations</H4>
					<RText>
						Users wanted to be prepared for which documents were needed at each step, along with the time that they would need to spend on it.
					</RText>
				</li>
				<li>
					<H4>Provide confirmations</H4>
					<RText>
						Some participants were confused about the start and end of every step and wanted to be reassured while dealing with their finances.
					</RText>
				</li>
			</ThreeColumns>
			<StyleGuide />
			<ImageSection
				title="Creating approachable design elements"
				description={[
					'User interface elements, such as buttons, fields and selections, are designed to impart a sense of approachability and warmth, adding a touch of softness to the overall aesthetic.'
				]}
				images={[DesignElementsImage]}
			/>
			<ImageSection
				title="The role of a virtual assistant illustrations"
				description={[
					'Incorporating a virtual assistant depicted through simple human illustrations significantly elevates the user experience by providing guidance in a friendly manner.',
					'This personalized touch infuses warmth and familiarity into the platform, fostering a deeper connection with users and enhancing their interaction with the platform.'
				]}
				images={[VirtualAssistantImage]}
				margin={false}
			/>
			<ThreeColumns
				caption="SECOND ROUND OF TESTING"
				title="Eliminating issues to optimize usability"
				description={[
					<>
						During the following round of user testing, I provided participants with access to the platform's prototype and employed the{' '}
						<Bold>‘Think out loud’ method.</Bold>
					</>,
					'This technique encouraged participants to vocalize their thoughts and impressions as they navigated through the prototype, allowing me to gain valuable insights into the experience, understand their thought processes, and identify areas for improvement.'
				]}
			>
				<li>
					<H4>Simplify the language</H4>
					<RText>
						One suggestion was changing the button ‘Proceed’ to ‘Continue’ for a more user-friendly tone, which was implemented to enhance the
						platform’s accessibility.
					</RText>
				</li>
				<li>
					<H4>Provide pathways back</H4>
					<RText>
						This round of testing highlighted a need for pathways back to the dashboard at various stages of the process, addressing a previously
						overlooked usability issue.
					</RText>
				</li>
				<li>
					<H4>Explain the terms</H4>
					<RText>
						I also discovered that some terms were still unclear, so I had to provide additional tips and explanations to improve comprehension and
						user engagement.
					</RText>
				</li>
			</ThreeColumns>
			<FinalIterations />
			<Reflection
				points={[
					{
						title: 'Nuances of designing a financial platform',
						description: [
							'Extra caution is required when designing a platform related to finances, as individuals tend to be particularly sensitive and vigilant about their money. The fear of accidentally making an error and losing funds adds a layer of complexity to the design process.',
							'As designers, our responsibility is fostering a sense of security and comfort for users, reassuring them that they can safely navigate our platform.'
						]
					},
					{
						title: 'Importance of expert consultation',
						description: [
							'Obtaining insights from experts is crucial, especially regarding the content of your work.',
							'While users may find the interface satisfactory, they may not recognize inaccuracies or issues when it comes to complex topics such as tax filing or other unfamiliar subjects. Therefore, consulting with specialists is critical to ensure your platform is on the right path and is genuinely beneficial and usable for the end-users.'
						]
					}
				]}
			/>
		</div>
	);
};

export default EZTax;
