import { useEffect, useState } from 'react';
import { sizes } from '../styles';

export type Breakpoints = [number, number];

export enum Size {
	Desktop,
	Tablet,
	Phone
}

const useResponsive = (breakpoints: Breakpoints = [0, 0]) => {
	const [width, setWidth] = useState(window.innerWidth);

	const updateWidth = () => setWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', updateWidth);
		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	if (width > (breakpoints[0] || sizes.tablet)) return Size.Desktop;
	if (width > (breakpoints[1] || sizes.phone)) return Size.Tablet;
	return Size.Phone;
};

export default useResponsive;
