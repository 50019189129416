import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import ProblemImage1 from '../../assets/interac/old-1.png';
import ProblemImage2 from '../../assets/interac/old-2.png';
import ProblemImage3 from '../../assets/interac/old-3.png';
import ThreeImages from '../../components/threeImages';

const Problem = () => (
	<Section>
		<SectionCaption>THE PROBLEM</SectionCaption>
		<SectionTitle>Emulator has never been updated</SectionTitle>
		<SectionDescription>
			The emulator’s design is outdated, negatively impacting the institution's reputation and technological advancement, so it was time for Interac to
			rebuild it.
		</SectionDescription>
		<SectionDescription>
			The main goal was refreshing its user experience and extending the platform’s functionality with new proposed features.
		</SectionDescription>
		<ThreeImages images={[ProblemImage1, ProblemImage2, ProblemImage3]} />
	</Section>
);

export default Problem;
