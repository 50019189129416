import { H4, RText } from '../../components/texts';
import { Section, SectionTitle, SectionCaption, SectionDescription } from '../../sections';
import { FiImage } from './lowfi';
import styled from 'styled-components';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right.svg';
import useResponsive, { Size } from '../../hooks/useResponsive';
import { FunctionComponent } from 'react';
import NumberedList from '../../components/list';

const Arrow = styled(ArrowRightIcon)`
	position: absolute;
	width: 3.2rem;
	right: -2.4rem;
	top: 50%;
	transform: translate(50%, -50%);
`;

type Step = {
	title: string;
	description: string;
	image: string;
};

type Props = {
	caption: string;
	title: string;
	description: string[];
	steps: [Step, Step];
};

const MidHiFi: FunctionComponent<Props> = ({ caption, title, description, steps }) => {
	const size = useResponsive();

	return (
		<Section>
			<SectionCaption>{caption}</SectionCaption>
			<SectionTitle>{title}</SectionTitle>
			{description.map((paragraph, i) => (
				<SectionDescription key={i}>{paragraph}</SectionDescription>
			))}
			<NumberedList style={{ marginTop: '2.4rem' }}>
				<li>
					<H4>{steps[0].title}</H4>
					<RText style={{ marginBottom: 'auto' }}>{steps[0].description}</RText>
					<div style={{ position: 'relative', width: '100%' }}>
						<FiImage src={steps[0].image} />
						{size === Size.Desktop && <Arrow />}
					</div>
				</li>
				<li>
					<H4>{steps[1].title}</H4>
					<RText style={{ marginBottom: 'auto' }}>{steps[1].description}</RText>
					<div>
						<FiImage src={steps[1].image} />
					</div>
				</li>
			</NumberedList>
		</Section>
	);
};

export default MidHiFi;
