import { Section, SectionTitle, SectionCaption, SectionDescription } from '../../sections';
import useResponsive, { Size } from '../../hooks/useResponsive';
import { Question, Questions } from '../../components/question';

const Research = () => {
	const size = useResponsive();

	return (
		<Section>
			<SectionCaption>RESEARCH</SectionCaption>
			<SectionTitle>Questions that needed to be answered during the research</SectionTitle>
			<SectionDescription>
				I conducted user interviews with 6 participants to assess the challenges that they faced and the workarounds they employed.
			</SectionDescription>
			<SectionDescription>Here are some questions I had asked:</SectionDescription>
			<Questions>
				<Question>How and why do you use WIP?</Question>
				<Question>{size === Size.Phone ? 'What software do you use for WIP?' : 'What software do you use to create WIP?'}</Question>
				<Question>
					{size === Size.Phone
						? 'What is the key information for WIP?'
						: 'What is the most important information you want to see on the screen?'}
				</Question>
				<Question>{size === Size.Phone ? 'What challenges do you face?' : 'What challenges do you face at every stage?'}</Question>
				<Question>How do you overcome them?</Question>
			</Questions>
		</Section>
	);
};

export default Research;
