import { ChangeEvent, FormEvent, FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { H3, RText, Text, TextSmall, UnderlinedText } from './texts';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { ReactComponent as UnlockIcon } from '../assets/icons/unlock.svg';
import { ReactComponent as BigLockIcon } from '../assets/icons/biglock.svg';
import jsSHA from 'jssha';
import { useLocation } from 'wouter';

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	background-color: ${props => props.theme.primary_grey};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 450px;
	margin: 0 20px;
`;

const PasswordText = styled(RText)`
	text-align: center;
	margin-top: 1.6rem;
`;

const CloseButton = styled.div`
	cursor: pointer;
	position: absolute;
	top: 64px;
	right: 64px;

	& svg {
		width: 36px;
		height: 36px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		top: 32px;
		right: 32px;

		& svg {
			width: 24px;
			height: 24px;
		}
	}
`;

const SubmitButton = styled.button.attrs(() => ({ type: 'submit' }))`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	cursor: pointer;
	border: none;
	background: none;
	outline: none;

	& ${UnderlinedText} {
		margin-right: 0.8rem;
	}

	&:hover ${UnderlinedText}::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}
`;

const PasswordInput = styled.input.attrs(() => ({ type: 'password' }))<{ $isIncorrect: boolean }>`
	outline: none;
	background: ${props => props.theme.primary_white};
	color: ${props => props.theme.primary_black};
	border: 1px solid ${props => (props.$isIncorrect ? props.theme.error_red : props.theme.primary_black)};
	border-radius: 4px;
	width: 100%;
	padding: 1.6rem;
	font-size: 2rem;

	&::placeholder {
		font-family: 'Kumbh Sans', sans-serif;
		font-size: 2rem;
		font-weight: 300;
		color: ${props => props.theme.tertiary_grey};

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			font-size: 1.6rem;
		}
	}
`;

const LockedInput = styled.div`
	position: relative;
	margin-top: 12rem;
	margin-bottom: 10rem;
	max-width: 30rem;
	width: 100%;

	& svg {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -80%);
		z-index: -1;
	}
`;

const ErrorMessage = styled(TextSmall)`
	color: ${props => props.theme.error_red};
`;

type OverlayProps = {
	passwordHash: string;
	closeOverlay: () => void;
	unlock: () => void;
};

const Overlay: FunctionComponent<OverlayProps> = ({ passwordHash, closeOverlay, unlock }) => {
	const [password, setPassword] = useState('');
	const [isIncorrect, setIsIncorrect] = useState(false);

	const checkPassword = (event: FormEvent) => {
		event.preventDefault();

		const shaObject = new jsSHA('SHA-384', 'TEXT', { encoding: 'UTF8' });
		shaObject.update(password);
		const hash = shaObject.getHash('HEX');

		if (hash === passwordHash) {
			unlock();
			return;
		}

		setPassword('');
		setIsIncorrect(true);
	};

	const onPasswordChange = (e: ChangeEvent) => {
		setPassword((e.target as HTMLInputElement).value);
		setIsIncorrect(false);
	};

	return (
		<Container>
			<CloseButton onClick={closeOverlay}>
				<CloseIcon />
			</CloseButton>

			<Form onSubmit={checkPassword}>
				<H3>Enter Password</H3>
				<PasswordText>
					Enter the password to unlock the case study, and if you don’t have one - <span style={{ textDecoration: 'underline' }}>message me!!</span>
				</PasswordText>

				<LockedInput>
					<PasswordInput placeholder="Password" value={password} $isIncorrect={isIncorrect} onChange={onPasswordChange} autoFocus />
					<BigLockIcon />
					{isIncorrect && <ErrorMessage>Your password is incorrect.</ErrorMessage>}
				</LockedInput>

				<SubmitButton>
					<Text>
						<UnderlinedText>UNLOCK</UnderlinedText>
					</Text>
					<UnlockIcon />
				</SubmitButton>
			</Form>
		</Container>
	);
};

type Props = {
	password: string;
	children: ReactNode;
};

// password: SHA384 hash of the correct password.
const PasswordProtect: FunctionComponent<Props> = ({ password, children }) => {
	const [, setLocation] = useLocation();
	const [isLocked, setIsLocked] = useState(true);

	if (!isLocked) return <>{children}</>;
	return <Overlay passwordHash={password} closeOverlay={() => setLocation('/')} unlock={() => setIsLocked(false)} />;
};

export default PasswordProtect;
