import { Text } from '../../components/texts';
import { Section, SectionDescription, SectionTitle } from '../../sections';
import PersonaImage1 from '../../assets/eztax/persona1.png';
import PersonaImage2 from '../../assets/eztax/persona2.png';
import styled, { ThemeProvider } from 'styled-components';
import { FunctionComponent } from 'react';
import { BlockTitle, Block, PersonaInfoDescriptionProps, PersonaText, PersonaTitle, RPersonaInfoDescription, PersonaList } from '../../components/personas';
import { theme } from '../../styles';

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'info desc bhvr'
		'info desc need';
	grid-auto-columns: 1fr 2fr 2fr;
	grid-gap: 24px;

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) and (max-width: ${props => props.theme.tablet_width}) {
		& ${Text} {
			font-size: 1.8rem;
		}

		grid-template-areas:
			'info desc'
			'bhvr need';

		& > * {
			grid-column: span 5;
		}

		& > *:nth-child(1) {
			grid-column: span 4;
		}

		& > *:nth-of-type(2) {
			grid-column: span 6;
		}
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		& ${Text} {
			font-size: 1.1rem;
		}

		grid-gap: 12px;
		grid-template-areas:
			'info info'
			'bhvr need';
		grid-auto-columns: 1fr 1fr;
	}
`;

type PersonaProps = PersonaInfoDescriptionProps & {
	title: string;
	behaviors: string[];
	needs: string[];
};

const Persona: FunctionComponent<PersonaProps> = ({ title, image, info, status, description, behaviors, needs }) => (
	<>
		<PersonaTitle>{title}</PersonaTitle>
		<Container>
			<RPersonaInfoDescription image={image} info={info} status={status} description={description} />
			<Block $area="bhvr">
				<BlockTitle>Behaviours</BlockTitle>
				<PersonaList>
					{behaviors.map((paragraph, i) => (
						<li key={i}>
							<PersonaText>{paragraph}</PersonaText>
						</li>
					))}
				</PersonaList>
			</Block>
			<Block $area="need">
				<BlockTitle>Needs</BlockTitle>
				<PersonaList>
					{needs.map((paragraph, i) => (
						<li key={i}>
							<PersonaText>{paragraph}</PersonaText>
						</li>
					))}
				</PersonaList>
			</Block>
		</Container>
	</>
);

const block_theme = {
	background: theme.primary_grey,
	blocks: {
		desc: theme.primary_white
	},
	phone_background: theme.primary_white,
	phone_blocks: {
		info: theme.primary_grey
	}
};

const Personas = () => (
	<ThemeProvider theme={block_theme}>
		<Section>
			<SectionTitle>Cases of interaction with Canadian taxes</SectionTitle>
			<SectionDescription>To classify our users based on their behaviours and needs, I identified 2 personas presented below:</SectionDescription>
			<Persona
				title="Recent graduate dealing with taxes"
				image={PersonaImage1}
				info={[
					['Role', 'Animator'],
					['Age', '24'],
					['Location', 'Oakville'],
					['Status', 'Newcomer']
				]}
				status="Recent graduate"
				description={[
					'Originally from another country, Louis finds Canadian tax system overwhelming, so he navigates it with the help of an accountant.',
					'Eager to understand the tax system better, he is looking for something to simplify the complexities of taxes and make the learning process easier.'
				]}
				behaviors={['Relies on an accountant.', 'Struggles to navigate tax information online.']}
				needs={['Tax concepts and jargon explanation.']}
			/>
			<Persona
				title="Working immigrant dealing with taxes"
				image={PersonaImage2}
				info={[
					['Role', 'Manager'],
					['Age', '28'],
					['Location', 'Brampton'],
					['Status', 'Has a PR']
				]}
				status="Working Immigrant"
				description={[
					'Naomi is a manager who has called Canada home for the past 5 years, so she has a solid understanding of basic local tax processes.',
					'Despite her knowledge, Naomi finds the process confusing and time-consuming and wishes for a more effective way to manage her taxes.'
				]}
				behaviors={['Knows basic tax processes.', 'Values independence but finds filing taxes time-consuming.']}
				needs={['Streamlining tax-related processes.']}
			/>
		</Section>
	</ThemeProvider>
);

export default Personas;
