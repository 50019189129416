import styled from 'styled-components';
import { H3, UnderlinedText, RText } from '../components/texts';
import PlugImage from '../assets/icons/plug.svg';
import PlugVerticalImage from '../assets/icons/plug_vertical.svg';
import SocketImage from '../assets/icons/socket.svg';
import SocketVerticalImage from '../assets/icons/socket_vertical.svg';
import { ReactComponent as PageNotFoundIcon } from '../assets/icons/404.svg';
import { ReactComponent as ReloadIcon } from '../assets/icons/reload.svg';
import createResponsiveElement from '../components/responsive';
import useResponsive, { Size } from '../hooks/useResponsive';
import useTitle from '../hooks/useTitle';

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: ${props => props.theme.primary_grey};

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
	}
`;

const HorizontalImage = styled.img`
	max-width: 25vw;
	width: 100%;
	height: auto;
`;

const VerticalImage = styled.img`
	max-height: 20vh;
	height: 100%;
	width: auto;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 50px;
	text-align: center;
`;

const Button = styled.div`
	cursor: pointer;
	margin-top: 10rem;
	display: flex;
	align-items: center;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 5rem;
		margin-bottom: 3rem;
	}
`;

const ButtonText = styled(UnderlinedText)`
	font-size: 2rem;
	font-weight: 500;
	margin-right: 0.8rem;
`;

const ErrorIcon = styled(PageNotFoundIcon)`
	width: 260px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		width: 210px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		width: 160px;
	}
`;

const ErrorMessage = styled(H3)`
	margin-bottom: 1.6rem;
	margin-top: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 1rem;
	}
`;

const Image = createResponsiveElement([HorizontalImage, HorizontalImage, VerticalImage]);

const PageNotFound = () => {
	useTitle('404');
	const size = useResponsive();

	return (
		<Container>
			<Image src={size === Size.Phone ? PlugVerticalImage : PlugImage} />
			<Column>
				<ErrorIcon />
				<ErrorMessage>Page not found</ErrorMessage>
				<RText>Hold on until Ana gets the error fixed!!</RText>
				<RText style={{ margin: 0 }}>You may also refresh the page or try again later</RText>
				<Button onClick={() => window.location.reload()}>
					<ButtonText>RETRY</ButtonText>
					<ReloadIcon />
				</Button>
			</Column>
			<Image src={size === Size.Phone ? SocketVerticalImage : SocketImage} />
		</Container>
	);
};

export default PageNotFound;
