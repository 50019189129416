import styled from 'styled-components';
import { Caption, H233, RText } from '../components/texts';
import WebpImage from '../components/webp';

export const CoverImage = styled(WebpImage)`
	width: 100vw;
	height: auto;
`;

export const Section = styled.section`
	width: 100vw;
	display: flex;
	flex-direction: column;

	background: ${props => props.theme.primary_white};

	&:nth-of-type(even) {
		background: ${props => props.theme.primary_grey};
	}

	padding: 100px 200px 106px 200px;
	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		padding: 72px 100px 80px 100px;
	}
	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 56px 36px 64px 36px;
	}
`;

export const SectionCaption = styled(Caption)`
	margin-bottom: 2.4rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 1.6rem;
	}
`;

export const SectionTitle = styled(H233)`
	margin-bottom: 2.4rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 1.6rem;
	}
`;

export const SectionDescription = styled(RText)`
	max-width: 840px;
`;
