import { Text } from '../../components/texts';
import { Section, SectionDescription, SectionTitle } from '../../sections';
import PersonaImage1 from '../../assets/pledgx/persona1.svg';
import PersonaImage2 from '../../assets/pledgx/persona2.svg';
import styled, { ThemeProvider } from 'styled-components';
import { FunctionComponent } from 'react';
import { Block, BlockTitle, PersonaInfoDescriptionProps, PersonaList, PersonaText, PersonaTitle, RPersonaInfoDescription } from '../../components/personas';
import { theme } from '../../styles';

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'info desc usge'
		'quot cnrn goal';
	grid-gap: 24px;

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		grid-auto-columns: 11fr 16fr 14fr;
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		& ${Text} {
			font-size: 1.8rem;
		}

		grid-auto-columns: 1fr 1fr;
		grid-template-areas:
			'info desc'
			'quot usge'
			'cnrn goal';
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		& ${Text} {
			font-size: 1.1rem;
		}

		grid-gap: 12px;
		grid-template-areas:
			'info info'
			'usge usge'
			'cnrn goal';
	}
`;

const QuoteBlock = styled(Block)`
	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		display: none;
	}
`;

type PersonaProps = PersonaInfoDescriptionProps & {
	title: string;
	usage: string[];
	quote: string;
	concerns: string[];
	goals: string[];
};

const Persona: FunctionComponent<PersonaProps> = ({ title, image, info, status, description, usage, quote, concerns, goals }) => (
	<>
		<PersonaTitle>{title}</PersonaTitle>
		<Container>
			<RPersonaInfoDescription image={image} info={info} status={status} description={description} />
			<Block $area="usge">
				<BlockTitle>WIP Reports Use</BlockTitle>
				{usage.map((paragraph, i) => (
					<PersonaText key={i}>{paragraph}</PersonaText>
				))}
			</Block>
			<QuoteBlock $area="quot">
				<PersonaText style={{ margin: 'auto 0' }}>{quote}</PersonaText>
			</QuoteBlock>
			<Block $area="cnrn">
				<BlockTitle>Concerns</BlockTitle>
				<PersonaList>
					{concerns.map((concern, i) => (
						<li key={i}>
							<PersonaText>{concern}</PersonaText>
						</li>
					))}
				</PersonaList>
			</Block>
			<Block $area="goal">
				<BlockTitle>Goals</BlockTitle>
				<PersonaList>
					{goals.map((goal, i) => (
						<li key={i}>
							<PersonaText>{goal}</PersonaText>
						</li>
					))}
				</PersonaList>
			</Block>
		</Container>
	</>
);

const block_theme = {
	background: theme.primary_white,
	blocks: {
		desc: theme.primary_grey
	},
	phone_background: theme.primary_white,
	phone_blocks: {}
};

const Personas = () => (
	<ThemeProvider theme={block_theme}>
		<Section>
			<SectionTitle>WIP for different stakeholders</SectionTitle>
			<SectionDescription>To classify our users based on their goals and pain points, I came up with 2 personas presented below:</SectionDescription>
			<Persona
				title="Surety bond company representative using WIP"
				image={PersonaImage1}
				info={[
					['Role', 'Underwriter'],
					['Age', '32'],
					['Location', 'Toronto'],
					['Company', 'Surety Bond']
				]}
				status="Surety Representative"
				description={[
					'John works for a surety bond company that provides services to contractors.',
					'With a background in finance, he specializes in assessing contractors’ financial health and performance. He evaluates the risk associated with bonding a particular contractor for a project.'
				]}
				usage={[
					"John uses WIP reports to track contractor's trends and determine if they are a reliable investment.",
					'He gauges how a contractor manages projects and if they maintain profitability, which helps John decide on whether to bond them and support their future work.'
				]}
				quote="“If only more contractors understood that an accurate WIP report is a pledge of trust.”"
				concerns={['Possibility of project’s front-loading or over-billing.', 'Inaccurate contractor financial reporting']}
				goals={['Quick and accurate analysis of the project metrics.', 'Frequent updates on project progress to mitigate risks.']}
			/>
			<Persona
				title="Bank representative using WIP"
				image={PersonaImage2}
				info={[
					['Role', 'Analyst'],
					['Age', '58'],
					['Location', 'Toronto'],
					['Company', 'Bank']
				]}
				status="Bank Representative"
				description={[
					'Sarah is a credit analyst working for a bank providing financing and lines of credit to construction companies.',
					"With a strong financial background, she focuses on assessing the creditworthiness of construction businesses to minimize the bank's risk exposure."
				]}
				usage={[
					'Sarah relies on WIP reports to see the profitability of construction companies seeking financing. ',
					'WIP reports help her assess if a company accurately estimates and bills for job costs to assess its ability to repay loans and secure lines of credit for projects.'
				]}
				quote="“I wish every company recognized the power of a carefully crafted WIP report.”"
				concerns={['Inaccurate reporting impacting the ability to recover the loans.', 'Non-reliable WIP reports reflecting financial standing.']}
				goals={['Assesses cost estimation and billing practices.', 'Aid in making informed decisions about financing.']}
			/>
		</Section>
	</ThemeProvider>
);

export default Personas;
