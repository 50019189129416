import { FunctionComponent } from 'react';

type Props = {
	src: string;
	webpSrc: string;
};

// .webp images are much smaller than png/jpg, but they aren't supported by some
// old browsers, so we have to include both.
const WebpImage: FunctionComponent<Props> = ({ src, webpSrc, ...props }) => (
	<picture>
		<source type="image/webp" srcSet={webpSrc} />
		<img src={src} alt="" {...props} />
	</picture>
);

export default WebpImage;
