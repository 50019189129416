import styled from 'styled-components';

const PageContainer = styled.div`
	padding: 124px 200px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		padding: 124px 100px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 64px 36px;
	}
`;

export default PageContainer;
