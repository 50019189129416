import { FunctionComponent } from 'react';
import { Section, SectionCaption, SectionTitle } from '../../sections';
import { H4, RText } from '../../components/texts';
import DesignImage1 from '../../assets/crypto/high-fi-1.png';
import DesignImage2 from '../../assets/crypto/high-fi-2.png';
import DesignImage3 from '../../assets/crypto/high-fi-3.png';
import DesignImage4 from '../../assets/crypto/high-fi-4.png';
import DesignImage5 from '../../assets/crypto/high-fi-5.png';
import DesignImage6 from '../../assets/crypto/high-fi-6.png';
import DesignImage7 from '../../assets/crypto/high-fi-7.png';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		&:nth-child(even) {
			flex-direction: row-reverse;
		}
	}

	margin-top: 8rem;
	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		&:first-of-type {
			margin-top: 3.2rem;
		}

		flex-direction: column-reverse;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		&:first-of-type {
			margin-top: 2.4rem;
		}

		margin-top: 4.8rem;
	}
`;

const ImageContainer = styled.div`
	width: 45%;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		width: 100%;
		max-width: 840px;
		margin-top: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const TextContainer = styled.div`
	width: 45%;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		width: 100%;
		max-width: 840px;
	}
`;

const Title = styled(H4)`
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 0.8rem;
	}
`;

type Props = {
	title: string;
	description: string[];
	image: string;
};

const Design: FunctionComponent<Props> = ({ title, description, image }) => (
	<Container>
		<ImageContainer>
			<Image src={image} alt="" />
		</ImageContainer>
		<TextContainer>
			<Title>{title}</Title>
			{description.map((paragraph, i) => (
				<RText key={i}>{paragraph}</RText>
			))}
		</TextContainer>
	</Container>
);

const Designs = () => {
	return (
		<Section>
			<SectionCaption>HIGH-FIDELITY DESIGNS</SectionCaption>
			<SectionTitle>Final cryptocurrency trading experience</SectionTitle>
			<Design
				title="See actual crypto prices"
				description={[
					'Crypto Insights allows you to keep track of cryptocurrency on different stocks in one place, giving you more opportunities for trading.',
					'For the convenience of using the app, I developed a design system that includes dark and light modes.'
				]}
				image={DesignImage1}
			/>
			<Design
				title="Access the news easily"
				description={[
					'Crypto Insights allows you to keep track of cryptocurrency on different stocks in one place, giving you more opportunities for trading.'
				]}
				image={DesignImage2}
			/>
			<Design
				title="Track your portfolio"
				description={['Synchronize with stocks or your e-wallet and make your transactions more accessible by organizing them in a portfolio.']}
				image={DesignImage3}
			/>
			<Design
				title="Get signals to trade efficiently"
				description={[
					'Be notified when it is time to buy or sell cryptocurrency using signals. The information for this feature is collected and analyzed by the app.'
				]}
				image={DesignImage4}
			/>
			<Design
				title="Change the basic settings"
				description={['Adjust your settings by choosing the desired colour mode and general push notifications.']}
				image={DesignImage5}
			/>
			<Design
				title="Manage your devices"
				description={['Link your devices and track your active sessions. Use QR-code to make the process of connecting a gadget easy and fast.']}
				image={DesignImage6}
			/>
			<Design
				title="Choose the subscription plan"
				description={['Switch between the subscription plans to try their features and choose one that suits you the most.']}
				image={DesignImage7}
			/>
		</Section>
	);
};

export default Designs;
