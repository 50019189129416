import { ImageCaption } from '../../components/texts';
import { Section, SectionDescription, SectionTitle } from '../../sections';
import ExcelImage from '../../assets/pledgx/excel.png';
import styled from 'styled-components';

const Image = styled.img`
	margin-top: 4.8rem;
	margin-bottom: 1.6rem;
	width: 100%;
	height: auto;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.2rem;
		margin-bottom: 0.8rem;
	}
`;

const Underwriting = () => (
	<Section>
		<SectionTitle>Underwriting is stuck in Excel 2003</SectionTitle>
		<SectionDescription>
			Construction accounting departments use spreadsheets for manual data entry for more than 50% of their processes, which is time-consuming and leaves plenty of room for error.
		</SectionDescription>
		<Image src={ExcelImage} alt="Excel spreadsheet" />
		<ImageCaption>an example of a WIP report</ImageCaption>
	</Section>
);

export default Underwriting;
