import styled from 'styled-components';
import { Section, SectionCaption, SectionTitle } from '../../sections';
import { NumberedListBase } from '../../components/list';
import Image1 from '../../assets/eztax/highfi-1.png';
import Image2 from '../../assets/eztax/highfi-2.png';
import Image3 from '../../assets/eztax/highfi-3.png';
import Image4 from '../../assets/eztax/highfi-4.png';
import Image5 from '../../assets/eztax/highfi-5.png';
import { H4, Text, RText } from '../../components/texts';

const NumberedList = styled(NumberedListBase)`
	display: flex;
	flex-direction: column;
	margin-top: 2.4rem;

	& > li {
		display: flex;
		flex-direction: column;

		&:not(:first-child) {
			margin-top: 4.8rem;

			@media only screen and (max-width: ${props => props.theme.tablet_width}) {
				margin-top: 3.6rem;
			}
		}

		& > ${Text} {
			max-width: 800px;
		}
	}
`;

const ItemTitle = styled(H4)`
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 0.8rem;
	}
`;

const Image = styled.img`
	margin-top: 2.4rem;
	max-width: 1280px;
`;

const FinalIterations = () => (
	<Section>
		<SectionCaption>FINAL ITERATIONS</SectionCaption>
		<SectionTitle style={{ marginBottom: 0 }}>Implementing the design elements for a cohesive UX</SectionTitle>
		<NumberedList>
			<li>
				<ItemTitle>Learning tax concepts module</ItemTitle>
				<RText>
					The platform's first section teaches users key tax concepts through brief explanations and following questions. Correct answers unlock the
					next module, using gamification to make it easy and engaging.
				</RText>
				<Image src={Image1} />
			</li>
			<li>
				<ItemTitle>Filing tax returns module</ItemTitle>
				<RText>
					The second section guides users through filing tax returns, clarifying unfamiliar concepts as needed. It analyzes user inputs and suggests
					required data and tax forms, streamlining the process.
				</RText>
				<Image src={Image2} />
			</li>
			<li>
				<ItemTitle>Virtual assistant component</ItemTitle>
				<RText>
					To enhance user experience and make it more user-friendly, I've implemented a virtual assistant that guides users through the tax filing
					process, offering personalized support and easy navigation.
				</RText>
				<Image src={Image3} />
			</li>
			<li>
				<ItemTitle>Tips and user guidance</ItemTitle>
				<RText>
					I've added brief explanations under each step to clarify instructions and unfamiliar terms for users. Additionally, I’ve included links to
					the official CRA website for more details on specific topics.
				</RText>
				<Image src={Image4} />
			</li>
			<li>
				<ItemTitle>Review and edit</ItemTitle>
				<RText>
					Upon completing the process, users can review provided information and make necessary edits. For tax forms uploaded as .PDFs, the platform
					transcribes the data, automatically filling in fields.
				</RText>
				<Image src={Image5} />
			</li>
		</NumberedList>
	</Section>
);

export default FinalIterations;
