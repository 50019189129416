import styled from 'styled-components';
import PageContainer from '../components/page';
import { H223, ImageCaption, RText } from '../components/texts';
import MainImage from '../assets/fun/main.jpg';
import Image1 from '../assets/fun/image1.jpg';
import Image2 from '../assets/fun/image2.jpg';
import Image3 from '../assets/fun/image3.jpg';
import Image4 from '../assets/fun/image4.jpg';
import Image5 from '../assets/fun/image5.jpg';
import Image6 from '../assets/fun/image6.jpg';
import MainImageWebp from '../assets/fun/main.webp';
import Image1Webp from '../assets/fun/image1.webp';
import Image2Webp from '../assets/fun/image2.webp';
import Image3Webp from '../assets/fun/image3.webp';
import Image4Webp from '../assets/fun/image4.webp';
import Image5Webp from '../assets/fun/image5.webp';
import Image6Webp from '../assets/fun/image6.webp';
import WebpImage from '../components/webp';
import useTitle from '../hooks/useTitle';

const Title = styled(H223)`
	margin-bottom: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 2.4rem;
	}
`;

const Text = styled(RText)`
	max-width: 600px;

	&:first-of-type {
		margin-bottom: 2rem;

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			margin-bottom: 1.6rem;
		}
	}
`;

const ImageContainer = styled.div`
	margin-top: 15.6rem;
	margin-bottom: 10.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 11.6rem;
		margin-bottom: 8.8rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 7.2rem;
		margin-bottom: 6.4rem;
	}
`;

const BigImage = styled(WebpImage)`
	width: 100%;
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 0.8rem;
	}
`;

const ImageRow = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 32px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		column-gap: 24px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		column-gap: 16px;
	}

	&:not(:last-child) {
		margin-bottom: 32px;

		@media only screen and (max-width: ${props => props.theme.tablet_width}) {
			margin-bottom: 24px;
		}

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			margin-bottom: 16px;
		}
	}
`;

const Image = styled(WebpImage)`
	width: 100%;
	height: auto;
`;

const Fun = () => {
	useTitle('Fun');

	return (
		<PageContainer>
			<Title>Creativity outside of work</Title>
			<Text>I try to stay creative and expresses myself in multiple ways, especially through painting and fine art.</Text>
			<Text>Below, you can find some pieces I made for fun 🎨</Text>
			<ImageContainer>
				<BigImage src={MainImage} webpSrc={MainImageWebp} />
				<ImageCaption>Currently working on the Toronto skyline acrylic painting on canvas (16”x20” each)</ImageCaption>
			</ImageContainer>
			<ImageRow>
				<Image src={Image1} webpSrc={Image1Webp} />
				<Image src={Image2} webpSrc={Image2Webp} />
			</ImageRow>
			<ImageRow>
				<Image src={Image3} webpSrc={Image3Webp} />
				<Image src={Image4} webpSrc={Image4Webp} />
			</ImageRow>
			<ImageRow>
				<Image src={Image5} webpSrc={Image5Webp} />
				<Image src={Image6} webpSrc={Image6Webp} />
			</ImageRow>
		</PageContainer>
	);
};

export default Fun;
