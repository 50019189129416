import styled from 'styled-components';
import { RText } from '../../components/texts';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import DefaultDashboardImage from '../../assets/interac/dashboards_web/Default.png';
import TDDashboardImage from '../../assets/interac/dashboards_web/TD.png';
import BMODashboardImage from '../../assets/interac/dashboards_web/BMO.png';
import ScotiaDashboardImage from '../../assets/interac/dashboards_web/Scotia.png';
import RBCDashboardImage from '../../assets/interac/dashboards_web/RBC.png';
import CIBCDashboardImage from '../../assets/interac/dashboards_web/CIBC.png';
import useResponsive, { Size } from '../../hooks/useResponsive';
import { ReactComponent as DefaultLogo } from '../../assets/interac/logos/default.svg';
import { ReactComponent as TDLogo } from '../../assets/interac/logos/TD.svg';
import { ReactComponent as BMOLogo } from '../../assets/interac/logos/BMO.svg';
import { ReactComponent as ScotiaLogo } from '../../assets/interac/logos/Scotia.svg';
import { ReactComponent as RBCLogo } from '../../assets/interac/logos/RBC.svg';
import { ReactComponent as CIBCLogo } from '../../assets/interac/logos/CIBC.svg';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import createResponsiveElement from '../../components/responsive';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 4.8rem;
	max-width: 1280px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: column-reverse;
		margin-top: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const BoldText = styled(RText)`
	font-weight: 600;
	margin-top: 2.4rem !important;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 1.6rem !important;
	}
`;

const Image = styled.img`
	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		width: 75%;
	}

	height: auto;
	border: 1px solid ${props => props.theme.tertiary_grey};
	border-radius: 8px;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: row;
		margin-bottom: 2.4rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		&:last-of-type {
			margin-bottom: 1.2rem;
		}
	}
`;

const Button = styled.button<{ $isActive: boolean }>`
	width: 200px;
	height: 64px;
	background: ${props => (props.$isActive ? props.theme.primary_grey : props.theme.primary_white)};
	border: 1px solid ${props => (props.$isActive ? props.theme.primary_black : props.theme.tertiary_grey)};
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	padding: 2rem 1.6rem;
	cursor: pointer;
	transition: background 0.1s;

	&:hover {
		background: ${props => props.theme.primary_grey};
	}

	& > * {
		color: ${props => (props.$isActive ? props.theme.primary_black : props.theme.tertiary_grey)};
	}

	& path {
		fill: ${props => (props.$isActive ? props.theme.primary_black : props.theme.tertiary_grey)};
	}

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		max-width: 120px;
		width: 18%;
		height: 40px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		max-width: 120px;
		width: 30%;
		height: 40px;
	}
`;

enum Dashboard {
	Default,
	TD,
	BMO,
	Scotia,
	RBC,
	CIBC
}

const DASHBOARD_IMAGES = {
	[Dashboard.Default]: DefaultDashboardImage,
	[Dashboard.TD]: TDDashboardImage,
	[Dashboard.BMO]: BMODashboardImage,
	[Dashboard.Scotia]: ScotiaDashboardImage,
	[Dashboard.RBC]: RBCDashboardImage,
	[Dashboard.CIBC]: CIBCDashboardImage
};

type Props = {
	children: ReactNode[];
};

const PhoneButtons: FunctionComponent<Props> = ({ children }) => {
	const size = children.length / 2;

	return (
		<>
			<Buttons>{children.slice(size)}</Buttons>
			<Buttons>{children.slice(0, size)}</Buttons>
		</>
	);
};

const RButtons = createResponsiveElement([Buttons, Buttons, PhoneButtons]);

const Research = () => {
	const size = useResponsive();
	const [dashboard, setDashboard] = useState(Dashboard.Default);

	useEffect(() => {
		if (size === Size.Tablet && dashboard === Dashboard.Default) setDashboard(Dashboard.TD);
	}, [dashboard, size]);

	return (
		<Section>
			<SectionCaption>RESEARCH & INSIGHTS</SectionCaption>
			<SectionTitle>Lack of customization</SectionTitle>
			<SectionDescription>
				After running the prototype with 5 users, we found that the generic appearance of the Emulator was causing difficulties in understanding and navigating the interface
				effectively.
			</SectionDescription>
			<SectionDescription>
				Financial institutions, primarily banks, emphasized the importance of aligning the Emulator’s UI with their brand identities. This customization would help them better
				comprehend the placement and reasoning behind each feature, facilitating a more intuitive user experience.
			</SectionDescription>
			<BoldText>Select a bank to see its dashboard design:</BoldText>
			<Container>
				<Image src={DASHBOARD_IMAGES[dashboard]} />
				<RButtons>
					{size !== Size.Tablet && (
						<Button onClick={() => setDashboard(Dashboard.Default)} $isActive={dashboard === Dashboard.Default}>
							<DefaultLogo style={{ transform: 'scale(0.9)' }} />
						</Button>
					)}
					<Button onClick={() => setDashboard(Dashboard.TD)} $isActive={dashboard === Dashboard.TD}>
						<TDLogo />
					</Button>
					<Button onClick={() => setDashboard(Dashboard.BMO)} $isActive={dashboard === Dashboard.BMO}>
						<BMOLogo style={{ transform: 'scale(0.9)' }} />
					</Button>
					<Button onClick={() => setDashboard(Dashboard.Scotia)} $isActive={dashboard === Dashboard.Scotia}>
						<ScotiaLogo />
					</Button>
					<Button onClick={() => setDashboard(Dashboard.RBC)} $isActive={dashboard === Dashboard.RBC}>
						<RBCLogo />
					</Button>
					<Button onClick={() => setDashboard(Dashboard.CIBC)} $isActive={dashboard === Dashboard.CIBC}>
						<CIBCLogo style={{ transform: 'scale(0.8)' }} />
					</Button>
				</RButtons>
			</Container>
		</Section>
	);
};

export default Research;
