import { ComponentType, FunctionComponent } from 'react';
import useResponsive, { Breakpoints, Size } from '../hooks/useResponsive';

type Element = ComponentType<any>;
type Elements = [Element, Element, Element];

type Props = {
	elements: Elements;
	breakpoints: Breakpoints;
};

// Elements which switch between 3 components based on screen width: [desktop, tablet, phone]
const ResponsiveElement: FunctionComponent<Props> = ({ elements, breakpoints, ...props }) => {
	const size = useResponsive(breakpoints);

	if (elements.length !== 3) throw new Error('ResponsiveElement: invalid arguments.');

	const [Desktop, Tablet, Phone] = elements;

	if (size === Size.Desktop) return <Desktop {...props} />;
	if (size === Size.Tablet) return <Tablet {...props} />;
	return <Phone {...props} />;
};

const createResponsiveElement = (elements: Elements, breakpointsOverride: Breakpoints = [0, 0]) => {
	return (props: any) => <ResponsiveElement elements={elements} breakpoints={breakpointsOverride} {...props} />;
};

export default createResponsiveElement;
