import DeviceVideo, { DeviceType } from '../../components/deviceVideo';
import useTitle from '../../hooks/useTitle';
import InteracDemo from '../../assets/interac/demo.mov';
import InteracMobileDemo from '../../assets/interac/demo_mobile.mov';
import Info from '../../sections/info';
import HMWSection from '../../sections/hmw';
import Problem from './problem';
import { CoverImage, Section } from '../../sections';
import InteracCoverWebp from '../../assets/interac/cover.webp';
import InteracCoverImage from '../../assets/interac/cover.png';
import Design from './design';
import Wireframe from './wireframe';
import Visual from './visual';
import Research from './research';
import TextSection from '../../sections/text';
import Dashboard from './dashboard';
import Customization from './customization';
import Reflection from '../../sections/reflection';
import HorizontalSiteMap from '../../assets/interac/sitemap_horizontal.png';
import VerticalSiteMap from '../../assets/interac/sitemap_vertical.png';
import HorizontalInteractionsImage from '../../assets/interac/interactions_horizontal.png';
import VerticalInteractionsImage from '../../assets/interac/interactions_vertical.png';
import ImageSection from '../../sections/image';
import CardsVerticalImage from '../../assets/interac/cards_vertical.svg';
import CardsHorizontalImage from '../../assets/interac/cards_horizontal.svg';
import PasswordProtect from '../../components/password';

const Interac = () => {
	useTitle('Interac');

	return (
		<PasswordProtect password="665a7828f24be98d90e9f46662292ecfecacc9f52405ce1970f6a02578665c530ed1afb4f4be6dbe698959959f73fb03">
			<CoverImage src={InteracCoverImage} webpSrc={InteracCoverWebp} />
			<Info
				title="Financial emulator redesign for Interac"
				description="Financial Emulator is an environment used to demo Interac’s new proposed features to financial institutions to foster customer relations, secure deals."
				info={[
					{ title: 'My Role', items: ['UX Designer', 'UI Designer'] },
					{ title: 'Timeline', items: ['8 weeks'] },
					{ title: 'Tools', items: ['Figma', 'FigJam'] }
				]}
			/>
			<Problem />
			<HMWSection>redesign FI Emulator to improve the digital banking experience?</HMWSection>
			<Section style={{ display: 'none' }} /> {/* Skip grey section */}
			<ImageSection
				caption="SITEMAP"
				title="Rethinking user experience"
				description={[
					'Before jumping straight to designing, our objective was to evaluate the convenience of the current navigation system and determine whether adjustments were necessary.',
					'Additionally, we analyzed which features should be added or modified across various screens to enhance the existing user experience.'
				]}
				images={[HorizontalSiteMap, VerticalSiteMap, VerticalSiteMap]}
			/>
			<Design />
			<Wireframe />
			<ImageSection
				caption="INTERFACE INTERACTIONS"
				title="Exploring navigation and feature accessibility"
				description={[
					'Users can easily access any page through the left side menu. In addition to that, it was decided to place prominent buttons on the dashboard for quick access to the Send and Receive pages, as our research revealed their frequent usage compared to other features.'
				]}
				images={[HorizontalInteractionsImage, HorizontalInteractionsImage, VerticalInteractionsImage]}
			/>
			<Visual />
			<DeviceVideo src={InteracDemo} type={DeviceType.Laptop} />
			<Research />
			<ImageSection
				caption="DESIGN COMPONENTS"
				title="Cards as visual design elements"
				description={[
					'As we decided to display the cards on the dashboard, I also came up with the ones that were close to the originals and supported the overall look and feel.'
				]}
				images={[CardsHorizontalImage, CardsHorizontalImage, CardsVerticalImage]}
			/>
			<TextSection
				caption="THE PROBLEM"
				title="Coming up with a mobile experience"
				description={[
					'During a discussion with our manager, we uncovered a backlog of tickets highlighting potential mobile features that could cater to our specific user category.',
					'This insight prompted us to prioritize the adaptation of our design to better suit the needs of mobile users, aligning with our focus on enhancing user experience across all platforms.'
				]}
			/>
			<HMWSection>reimagine the mobile experience of the FI Emulator to make it more intuitive and innovative?</HMWSection>
			<Section style={{ display: 'none' }} /> {/* Skip grey section */}
			<Dashboard />
			<DeviceVideo src={InteracMobileDemo} type={DeviceType.Phone} />
			<Customization />
			<Reflection
				points={[
					{
						title: 'Applying the same design concepts to different devices can be challenging',
						description: [
							'Ensuring an exceptional user experience went through multiple stages of iterative refinement and constant communication with users.',
							'Throughout the process, I had to employ creativity to deliver high-quality experiences while adhering to the best field practices.'
						]
					},
					{
						title: 'Learning how to work without a UX researcher on the team',
						description: [
							'With a relatively small team and no dedicated UX researcher for the project, we embraced a proactive approach to research. ',
							'This involved drawing inspiration from existing solutions and research done by others, while also conducting user tests to uncover the optimal solution.'
						]
					}
				]}
			/>
		</PasswordProtect>
	);
};

export default Interac;
