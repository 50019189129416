import styled from 'styled-components';
import { H234, Text } from './texts';
import { ReactComponent as EmailIcon } from '../assets/icons/email.svg';
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin.svg';

const Container = styled.footer`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: ${props => props.theme.primary_black};
	padding: 55px 10vw;
	margin-top: auto;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		flex-direction: column;
	}
`;

const TextColumn = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		width: 60%;
	}
`;

const FooterH234 = styled(H234)`
	color: ${props => props.theme.primary_grey};
`;

const FooterText = styled(Text)`
	margin-top: 1.5rem;
	color: ${props => props.theme.secondary_grey};
`;

const CodeSymbol = styled.span`
	color: ${props => props.theme.primary_yellow};
	font-weight: 700;
`;

const Links = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 2rem;
	width: 40%;

	@media only screen and (min-width: ${props => props.theme.min_tablet_width}) {
		justify-content: flex-end;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
	}
`;

const Link = styled.a`
	height: min-content;

	&:hover path {
		fill: ${props => props.theme.primary_white};
	}
`;

const Footer = () => (
	<Container>
		<TextColumn>
			<FooterH234>Thanks for visiting my portfolio!</FooterH234>
			<FooterText>
				Made with <CodeSymbol>&lt; &gt;</CodeSymbol> by Ana Nerush
			</FooterText>
		</TextColumn>
		<Links>
			<Link href="mailto:anastasiia.nerush@gmail.com">
				<EmailIcon />
			</Link>
			<Link target="_blank" href="https://www.linkedin.com/in/ananerush/">
				<LinkedInIcon />
			</Link>
		</Links>
	</Container>
);

export default Footer;
