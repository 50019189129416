import useTitle from '../../hooks/useTitle';
import CryptoCoverImage from '../../assets/crypto/cover.png';
import CryptoCoverWebp from '../../assets/crypto/cover.webp';
import DemoVideo from '../../assets/crypto/demo.mov';
import DeviceVideo, { DeviceType } from '../../components/deviceVideo';
import { CoverImage, Section } from '../../sections';
import Info from '../../sections/info';
import Reflection from '../../sections/reflection';
import HMWSection from '../../sections/hmw';
import TextSection from '../../sections/text';
import { H4 } from '../../components/texts';
import ThreeColumns from '../../sections/threeColumns';
import Research from './research';
import ImageSection from '../../sections/image';
import WireframeImage from '../../assets/crypto/low_fi-1.png';
import ColoursImage from '../../assets/crypto/colours.png';
import PhoneColoursImage from '../../assets/crypto/colours-mobile.png';
import Designs from './designs';

const CryptoInsights = () => {
	useTitle('CryptoInsights');

	return (
		<div>
			<CoverImage src={CryptoCoverImage} webpSrc={CryptoCoverWebp} />
			<Info
				title="Mobile application design for cryptocurrency traders"
				description="CryptoInsights is a mobile application that allows both beginners and professionals to earn money by cryptocurrency trading using just their phones."
				info={[
					{ title: 'My Role', items: ['UX/UI Designer', 'Interaction Designer'] },
					{ title: 'Timeline', items: ['8 weeks'] },
					{ title: 'Tools', items: ['Figma', 'Adobe CC'] }
				]}
			/>
			<TextSection
				caption="THE PROBLEM"
				title="Finding trading information in crypto is challenging"
				description={[
					'As technology advances, earning money becomes increasingly accessible. With growing interest in alternative revenue streams, cryptocurrency trading emerges as a profitable avenue.',
					'However, finding reliable information remains a challenge, especially when it comes to monitoring each cryptocurrency individually.'
				]}
			/>
			<HMWSection>streamline cryptocurrency trading to ensure thorough tracking of all currencies while optimizing overall efficiency?</HMWSection>
			<Section style={{ display: 'none' }} /> {/* Skip grey section */}
			<ThreeColumns
				caption="GOALS"
				description={[
					'Our goal for the project was to create an app that would help people discover the world of cryptocurrency or keep track of it if they already knew how to do it.',
					'Our high-level goals were to:'
				]}
			>
				<li>
					<H4>Make information about cryptocurrency more accessible.</H4>
				</li>
				<li>
					<H4>Increase the level of literacy in cryptocurrency among the population.</H4>
				</li>
				<li>
					<H4>Create a convenient and engaging platform for the crypto world.</H4>
				</li>
			</ThreeColumns>
			<TextSection
				caption="MY ROLE"
				title="From concept to 10,000+ downloads"
				description={[
					'As the sole designer, I handled all aspects of the system, covering user experience, visual and motion design, and marketing materials. Collaborating closely with developers, we defined and launched the MVP for Google Play.',
					'Following completion of materials for the initial release, I transitioned off the project.',
					'',
					'The app launched on the Play Market on September 14th, 2021, achieving over 10,000 downloads.'
				]}
			/>
			<DeviceVideo src={DemoVideo} type={DeviceType.Phone2} />
			<Research />
			<ImageSection
				caption="WIREFRAMING"
				title="Collaborative feature definition and UX mockup process"
				description={[
					'After collaborating with my team to identify essential features based on user needs and feedback, I initiated the process of mocking up the user experience.',
					'Throughout this phase, I maintained close communication with developers to ensure the feasibility and practicality of each feature.',
					'By consulting with them iteratively, we ensured that the implementation process remained efficient and justifiable in terms of time and resources.'
				]}
				images={[WireframeImage, WireframeImage, WireframeImage]}
			/>
			<ImageSection
				caption="DESIGN CONCEPT"
				title="Psychology of green in cryptocurrency"
				description={[
					'The research highlighted the significance of green hues, associated with growth, prosperity, and stability, making them ideal for a cryptocurrency trading app.',
					"Additionally, green symbolizes trust and reliability in a financial context. Given its association with positive trends in crypto markets, I integrated shades of green into the app's design to enhance visual appeal, build trust, and align with user expectations."
				]}
				images={[ColoursImage, ColoursImage, PhoneColoursImage]}
			/>
			<Designs />
			<Reflection
				points={[
					{
						title: 'Exploring unfamiliar topic',
						description: [
							'Navigating the world of cryptocurrency as a solo designer posed a major challenge.',
							'Despite initial unfamiliarity, I delved into thorough research, studying existing trading apps and consulting industry experts online to refine my approach.'
						]
					},
					{
						title: 'Designing from scratch',
						description: [
							'Creating a new design proved to be challenging as the company lacked an established brand or at least existing style guide.',
							"I had to devise a fresh concept that not only met user needs but also contributed to the team's success."
						]
					}
				]}
			/>
		</div>
	);
};

export default CryptoInsights;
