import { Section, SectionTitle, SectionCaption, SectionDescription } from '../../sections';
import useResponsive, { Size } from '../../hooks/useResponsive';
import { Question as Question_, Questions as Questions_ } from '../../components/question';
import styled from 'styled-components';

const ListElement = styled(SectionDescription)`
	position: relative;
	margin-left: 2rem;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -1.5rem;
		transform: translateY(-50%);
		width: 0.3rem;
		height: 0.3rem;
		background: ${props => props.theme.primary_black};
		border-radius: 50%;
	}

	& + & {
		margin-top: 0;
	}
`;

const Questions = styled(Questions_)`
	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		flex-direction: column;
	}
`;

const Question = styled(Question_)`
	@media only screen and (min-width: ${props => props.theme.min_desktop_width}) {
		font-size: 2.1rem;
	}
`;

const Research = () => {
	const size = useResponsive();

	return (
		<Section>
			<SectionCaption>RESEARCH</SectionCaption>
			<SectionTitle>Questions to be answered during the research</SectionTitle>
			<SectionDescription>
				To understand the scope of the problem better and find out if the existing assumptions were correct, I conducted user research with 10
				participants from 2 different groups:
			</SectionDescription>
			<ListElement>People who have never filed taxes themselves.</ListElement>
			<ListElement>Accountants connected with taxes on a professional level.</ListElement>
			<Questions>
				<Question>How do you feel about paying taxes?</Question>
				<Question>{size === Size.Phone ? 'How did you learn about taxes?' : 'How did you learn information about taxes?'}</Question>
				{size === Size.Desktop && <Question>Did you have to look for it yourself or did you receive it from an external source?</Question>}
				<Question>What sources did you use?</Question>
				<Question>{size === Size.Phone ? 'Describe your last experience.' : 'Describe your last experience of filing taxes.'}</Question>
				<Question>
					{size === Size.Phone
						? 'What were your common challenges?'
						: size === Size.Tablet
						? 'What are the common challenges you face?'
						: 'What are the common challenges you face when trying to understand tax concepts or file taxes?'}
				</Question>
				<Question>Do you file taxes yourself?</Question>
			</Questions>
		</Section>
	);
};

export default Research;
