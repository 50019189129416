import { styled } from 'styled-components';
import { ImageCaption } from '../../components/texts';
import { Section, SectionCaption, SectionDescription, SectionTitle } from '../../sections';
import LearningImage from '../../assets/eztax/lowfi-1.png';
import FilingImage from '../../assets/eztax/lowfi-2.png';

const Image = styled.img`
	width: 100%;
	height: auto;
	margin-top: 4.8rem;
	margin-bottom: 1.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
		margin-bottom: 0.4rem;
	}
`;

const FirstIterations = () => (
	<Section>
		<SectionCaption>FIRST ITERATIONS</SectionCaption>
		<SectionTitle>Creating a consistent design flow</SectionTitle>
		<SectionDescription>
			After outlining the platform's key features and overall flow, I delved into researching potential user steps, identifying areas that required
			clarification in the future.
		</SectionDescription>
		<SectionDescription>
			I sketched out a template for the standard presentation of content on the screen and then mapped it out on Figma, replacing the placeholders with
			the actual data.
		</SectionDescription>
		<div style={{ maxWidth: '1200px' }}>
			<Image src={LearningImage} />
			<ImageCaption>Learning tax concepts module initial wireframes</ImageCaption>
			<Image src={FilingImage} />
			<ImageCaption>Filing tax return module initial wireframes</ImageCaption>
		</div>
	</Section>
);

export default FirstIterations;
