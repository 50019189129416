import { styled } from 'styled-components';
import { Text, H345 } from './texts';
import { FunctionComponent } from 'react';
import createResponsiveElement from './responsive';

export const PersonaTitle = styled(H345)`
	margin-top: 6.4rem;
	margin-bottom: 2.4rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-top: 3.6rem;
		margin-bottom: 1.6rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-top: 2.4rem;
		margin-bottom: 1.2rem;
	}
`;

export const PersonaText = styled(Text)`
	font-size: 2.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		font-size: 1.8rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		font-size: 1.2rem;
	}
`;

export const DescriptionText = styled(PersonaText)`
	&:not(:first-of-type) {
		margin-top: 1.6rem;

		@media only screen and (max-width: ${props => props.theme.tablet_width}) {
			margin-top: 0.8rem;
		}

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			margin-top: 0.6rem;
		}
	}
`;

export const PersonaList = styled.ul`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		row-gap: 0.4rem;
	}
`;

const Image = styled.img`
	width: 150px;
	height: auto;
	margin: 0 auto;
	margin-bottom: 3.2rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 1.6rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		width: 100px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const InfoColumn = styled.div`
	display: flex;
	flex-direction: column;

	&:first-child {
		margin-right: 1.2rem;
		align-items: flex-end;

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			margin-right: 0.6rem;
		}
	}

	& > *:not(:first-child) {
		margin-top: 0.6rem;

		@media only screen and (max-width: ${props => props.theme.phone_width}) {
			margin-top: 0.2rem;
		}
	}
`;

const InfoText = styled(Text)`
	width: max-content;
`;

export type PersonaInfoProps = {
	image: string;
	info: [string, string][];
};

export const PersonaInfo: FunctionComponent<PersonaInfoProps> = ({ image, info }) => (
	<>
		<Image src={image} />
		<InfoRow>
			<InfoColumn>
				{info.map((item, i) => (
					<InfoText key={i} style={{ fontWeight: 600 }}>
						{item[0]}
					</InfoText>
				))}
			</InfoColumn>
			<InfoColumn>
				{info.map((item, i) => (
					<InfoText key={i}>{item[1]}</InfoText>
				))}
			</InfoColumn>
		</InfoRow>
	</>
);

export const Block = styled.div<{ $area: string }>`
	display: flex;
	flex-direction: column;
	grid-area: ${props => props.$area};
	border: 1px solid ${props => props.theme.secondary_grey};
	border-radius: 6px;
	padding: ${props => (props.$area === 'info' ? '4rem 1.5rem' : '4rem')};
	background: ${props => props.theme.blocks[props.$area] || props.theme.background};

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		padding: 2.4rem 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 1.8rem 2rem;
		background: ${props => props.theme.phone_blocks[props.$area] || props.theme.phone_background};
	}
`;

export const BlockTitle = styled(H345)`
	margin-bottom: 1.6rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		margin-bottom: 1.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		margin-bottom: 0.8rem;
	}
`;

export type PersonaInfoDescriptionProps = PersonaInfoProps & {
	status: string;
	description: string[];
};

const PersonaInfoDescription: FunctionComponent<PersonaInfoDescriptionProps> = ({ image, info, status, description }) => (
	<>
		<Block $area="info">
			<PersonaInfo image={image} info={info} />
		</Block>
		<Block $area="desc">
			<BlockTitle>{status}</BlockTitle>
			{description.map((paragraph, i) => (
				<DescriptionText key={i}>{paragraph}</DescriptionText>
			))}
		</Block>
	</>
);

const PhoneRow = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
`;

const PhoneColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const PersonaInfoDescriptionPhone: FunctionComponent<PersonaInfoDescriptionProps> = ({ image, info, description }) => (
	<Block $area="info">
		<PhoneRow>
			<PhoneColumn>
				<PersonaInfo image={image} info={info} />
			</PhoneColumn>
			<PhoneColumn>
				{description.map((paragraph, i) => (
					<DescriptionText key={i}>{paragraph}</DescriptionText>
				))}
			</PhoneColumn>
		</PhoneRow>
	</Block>
);

export const RPersonaInfoDescription = createResponsiveElement([PersonaInfoDescription, PersonaInfoDescription, PersonaInfoDescriptionPhone]);
