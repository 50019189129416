import { useEffect } from 'react';

const SEPARATOR = '|';

const useTitle = (title: string): void => {
	useEffect(() => {
		const originalTitle = document.title;
		document.title = `${originalTitle} ${SEPARATOR} ${title}`;

		return () => {
			document.title = originalTitle;
		};
	}, [title]);
};

export default useTitle;
