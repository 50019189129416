import styled from 'styled-components';
import { Text, UnderlinedText } from './texts';
import MenuButton from './menuButton';
import { FunctionComponent, ReactNode, useState } from 'react';
import { useLocation } from 'wouter';
import WouterLink from './link';

const NavBar = styled.nav<{ $transparent?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
	min-height: 64px;
	max-height: 64px;
	padding: 20px 200px;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		padding: 20px 100px;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		padding: 20px 36px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${props => props.theme.primary_white};
		z-index: -1;
		transition: opacity 0.3s;
		opacity: ${props => (props.$transparent === false ? '100%' : '90%')};
		border-bottom: 1px solid ${props => props.theme.secondary_grey};
	}
`;

const StaticNavBar = styled(NavBar)`
	position: relative;
	opacity: 0;
`;

const Logo = styled.h1`
	background: ${props => props.theme.primary_yellow};
	color: ${props => props.theme.primary_black};
	font-family: 'Lexend', sans-serif;
	font-weight: 600;
	font-size: 1.8rem;
	padding: 0 1.2rem;
	cursor: pointer;
`;

const LinkText = styled(Text)`
	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		font-size: 2.4rem;
	}
`;

const DesktopContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 4.8rem;

	@media only screen and (max-width: ${props => props.theme.tablet_width}) {
		column-gap: 3.2rem;
	}

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		display: none;
	}
`;

const PhoneContainer = styled.div`
	display: none;
	flex-direction: column;

	@media only screen and (max-width: ${props => props.theme.phone_width}) {
		display: flex;
	}
`;

const MenuOverlay = styled.div`
	position: absolute;
	z-index: 999;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	transform: translateY(100%);
	padding-top: 6.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${props => props.theme.primary_white};
	row-gap: 2.4rem;
`;

type LinkProps = {
	children: string;
	href: string;
};

const Link: FunctionComponent<LinkProps> = ({ href, children }) => {
	const [location] = useLocation();

	return (
		<WouterLink href={href}>
			<LinkText>
				<UnderlinedText $keepUnderlined={href === location}>{children}</UnderlinedText>
			</LinkText>
		</WouterLink>
	);
};

type LinksProps = {
	children: ReactNode[];
	isMenuOpened: boolean;
	setIsMenuOpened: (value: boolean) => void;
};

const Links: FunctionComponent<LinksProps> = ({ children, isMenuOpened, setIsMenuOpened }) => {
	return (
		<>
			<DesktopContainer>{children}</DesktopContainer>
			<PhoneContainer>
				<MenuButton isOpened={isMenuOpened} setIsOpened={setIsMenuOpened} />
				{isMenuOpened && <MenuOverlay onClick={() => setIsMenuOpened(false)}>{children}</MenuOverlay>}
			</PhoneContainer>
		</>
	);
};

const Header = () => {
	const [, setLocation] = useLocation();
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	return (
		<>
			<StaticNavBar />
			{/* ^ empty nav bar which takes up same amount of space as the moving nav bar */}
			<NavBar $transparent={!isMenuOpened}>
				<Logo onClick={() => setLocation('/')}>NERUSH</Logo>
				<Links isMenuOpened={isMenuOpened} setIsMenuOpened={setIsMenuOpened}>
					<Link href="/">Work</Link>
					<Link href="/fun">Fun</Link>
					<Link href="/about">About</Link>
					<a href="resume.pdf" target="_blank">
						<LinkText>
							<UnderlinedText>Resume</UnderlinedText>
						</LinkText>
					</a>
				</Links>
			</NavBar>
		</>
	);
};

export default Header;
